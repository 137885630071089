
import { makePostRequest } from 'lib/axios';
import { IInterestsForm } from '../hooks/useIntersts';

const recordInterests = async (
  payload: IInterestsForm,
): Promise<{ message: string }> => {
  const url = `/auth/interests`;
  const response = await makePostRequest(url, payload);
  return response.data.message;
};

export default recordInterests;
