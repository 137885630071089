import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import {
  DropdownLink,
  Link,
  NavigationLink,
} from 'hooks/useNavigationLinks';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import {  COLOR_LIGHT_BLUE } from 'constants/colors';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';

export interface SideNavLinkProps {
  link: NavigationLink; // link to display
  toggleLinkDropdown: (link: DropdownLink) => void; // open link dropdown
  closeSideNav: () => void;
}

const CaretIcon = styled.div`
  margin-left: auto;
  font-size: 21px;
`;

const DropdownList = styled.ul`
  list-style: none;

  li a {
    padding-left: 50px !important;
  }
`;

const TriggerText = styled(ParagraphText)`
  font-size: 16px;
  color: #fff;
  margin: 0;
`;

const ButtonTrigger = styled.button`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
    color: #fff;
  }

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};

    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }

    svg {
      color: ${COLOR_LIGHT_BLUE};
    }
  }
`;

const InternalLink = styled(NavLink)`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 2.5rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: 'transparent';

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};
    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }

    svg {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  span {
    font-size: 16px !important;
  }

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
    color: #fff;
  }

  &.active {
    background-color: ${COLOR_LIGHT_BLUE};

    &:focus,
    &:hover {
      color: #fff;
      ${TriggerText} {
        color: #fff;
      }

      svg {
        color: #fff;
      }
    }
  }
`;

const ExternalLink = styled.a`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};
    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  span {
    font-size: 16px !important;
  }

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
  }
`;

const SingleLinkTrigger = styled(NavLink)`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
    color: #fff;
  }

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};

    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }

    svg {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  &.active {
    background-color: ${COLOR_LIGHT_BLUE};
    &:focus,
    &:hover {
      color: #fff;
      ${TriggerText} {
        color: #fff;
      }

      svg {
        color: #fff;
      }
    }
  }
`;

function SideNavLink({
  link,
  toggleLinkDropdown,
  closeSideNav,
}: SideNavLinkProps) {
  if (link.type === 'dropdown') {
    const dropdownLink = link.data as DropdownLink;
    return (
      <li>
        <ButtonTrigger
          type="button"
          onClick={() => toggleLinkDropdown(dropdownLink)}
        >
          <div> {dropdownLink.icon}</div>
          <TriggerText>{dropdownLink.title}</TriggerText>
          <CaretIcon>
            {dropdownLink.active ? (
              <FontAwesomeIcon icon={faChevronRight} />
            ) : (
              <FontAwesomeIcon icon={faChevronLeft} />
            )}
          </CaretIcon>
        </ButtonTrigger>

        <DropdownList>
          {dropdownLink.active
            ? dropdownLink.children.map(sub => (
                <div key={sub.title}>
                  {sub.type === 'internal' ? (
                    <InternalLink
                      to={sub.to}
                      end
                      aria-hidden="true"
                      onClick={closeSideNav}
                    >
                      <div>{sub.icon}</div>

                      <TriggerText>{sub.title}</TriggerText>
                    </InternalLink>
                  ) : (
                    <ExternalLink
                      href={sub.to}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div>{sub.icon}</div>

                      <TriggerText>{sub.title}</TriggerText>
                    </ExternalLink>
                  )}
                </div>
              ))
            : null}
        </DropdownList>
      </li>
    );
  }

  const singleLink = link.data as Link;

  return (
    <li aria-hidden="true" onClick={closeSideNav}>
      <SingleLinkTrigger to={singleLink.to} end>
        <div>{singleLink.icon}</div>

        <TriggerText>{singleLink.title}</TriggerText>
      </SingleLinkTrigger>
    </li>
  );
}

export default SideNavLink;
