import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import getUserSession from 'features/auth/api/getUserSession';
import serverErrorHandler from 'services/serverError.service';
import getSclaApp from 'features/scla/api/getSclaApp';
import COHORT_YEAR from 'constants/year.constant';
import { dispatchLogout, dispatchSetUser, dispatchSetUserApplication } from '../store/dispatcher';

/**
 * This hook will fetch user profile data for the logged in user.
 * The data will only be fetched if there is user session token
 * but no user session profile data
 */
export default function useAuth() {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    const auth = getAuth();

    /**
     * Makes request for user data. Data is stored
     * in redux store
     */
    async function fetchUserAsync() {
      try {
        const response = await getUserSession();
        const application = await getSclaApp(COHORT_YEAR)

        dispatchSetUserApplication(application);
        dispatchSetUser(response);
      } catch (e) {
        serverErrorHandler(e);
      }
      setIsDone(true);
    }

    // listen for firebase user state change
    onAuthStateChanged(auth, async user => {
      if (user) {
        // since we have user, fetch user data
        fetchUserAsync();
      } else {
        // user is not logged, clear redux store
        dispatchLogout();
        setIsDone(true);
      }
    });
  }, []);

  return { isDone };
}
