import { Form, Input } from 'rsuite';
import React, { useState, useEffect, forwardRef } from 'react';
import SubHeader from 'components/Elements/Header/Header';
import HStack from 'components/Layout/HStack';
import VStack from 'components/Layout/VStack';
import { FormFieldSize } from 'components/Form/CustomForm';
import styled from 'styled-components';
import { COLOR_GREEN } from 'constants/colors';
import FormControlLabel from '../../Control/FormControlLabel';

export type Activity = {
  name: string;
  position: string;
  hours: string;
  grade: string;
};

type ActivitiesInputControlProps = {
  value: Activity[];
  onChange: any;
};

type ActivityItemProps = {
  rowValue: Activity;
  onChange: any;
  rowIndex: number;
};

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
  flex: 1 0 100%;
  @media (min-width: 992px) {
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const AddButton = styled.button`
  background-color: ${COLOR_GREEN};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 6px;
  padding: 8px 14px;
  p {
    color: #fff;
    font-size: 14px;
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

const Field = styled.div`
  flex: 1 0 100%;
  @media (min-width: 768px) {
    flex: 0 1 50%;
  }
`;

const FieldWrap = styled.div`
  display: flex;
  gap: 0px;
  flex-wrap: wrap;
  width: 100%;
`;

function ActivityItem({
  rowValue,
  onChange,
  rowIndex,
}: ActivityItemProps) {
  const handleChangeName = (value: string) => {
    onChange(rowIndex, { ...rowValue, name: value });
  };
  const handleChangePosition = (value: string) => {
    onChange(rowIndex, { ...rowValue, position: value });
  };
  const handleChangeHours = (value: string) => {
    onChange(rowIndex, { ...rowValue, hours: value });
  };

  const handleChangeGrade = (value: string) => {
    onChange(rowIndex, { ...rowValue, grade: value });
  };

  return (
    <VStack marginBottom={35}>
      <SubHeader marginBottom={15}>{`${rowIndex + 1}`}.</SubHeader>
      <HStack marginBottom={15} gap={35}>
        <FieldWrap>
          <Field>
            <FormControlLabel>
              Club/Organization/Company/Sports
            </FormControlLabel>
            <ControlWrapper>
              <Input value={rowValue.name} onChange={handleChangeName} />
            </ControlWrapper>
          </Field>
          <Field>
            <FormControlLabel>Position Held</FormControlLabel>
            <ControlWrapper>
              <Input
                value={rowValue.position}
                onChange={handleChangePosition}
              />
            </ControlWrapper>
          </Field>
        </FieldWrap>
      </HStack>
      <HStack gap={35}>
        <FieldWrap>
          <Field>
            <FormControlLabel>Hrs Per Week</FormControlLabel>
            <ControlWrapper>
              {' '}
              <Input value={rowValue.hours} onChange={handleChangeHours} />
            </ControlWrapper>
          </Field>
          <Field>
            <FormControlLabel>Grade Level</FormControlLabel>
            <ControlWrapper>
              {' '}
              <Input value={rowValue.grade} onChange={handleChangeGrade} />
            </ControlWrapper>
          </Field>
        </FieldWrap>
      </HStack>
    </VStack>
  );
}

function ActivitiesInputControl({
  value,
  onChange,
}: // fieldError,
ActivitiesInputControlProps) {
  const [activities, setActivities] = useState<Activity[]>(value);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (value.length === 0) {
        setActivities([{ name: '', position: '', grade: '', hours: '' }]);
      } else {
        setActivities(value);
      }
    }
    return () => {
      mounted = false;
    };
  }, [value]);

  const handleChangeProducts = (nextActivities: Activity[]) => {
    setActivities(nextActivities);
    onChange(nextActivities);
  };

  const handleInputChange = (rowIndex: number, val: Activity) => {
    const nextActivities = [...activities];
    nextActivities[rowIndex] = val;
    handleChangeProducts(nextActivities);
  };

  return (
    <div>
      {activities.map((rowValue, index) => (
        <ActivityItem
          key={index}
          rowIndex={index}
          rowValue={rowValue}
          onChange={handleInputChange}
        />
      ))}

      <AddButton
        onClick={() => {
          setActivities([
            ...activities,
            { name: '', position: '', grade: '', hours: '' },
          ]);
        }}
      >
        <p>Add Extra Curricular</p>
      </AddButton>
    </div>
  );
}

const ActivitiesFields = forwardRef((props: any, ref: any) => {
  const { name, size, helpMessage, label } = props;

  return (
    <FormGroup ref={ref} size={size}>
      <FormControlLabel>{label} </FormControlLabel>
      <ControlWrapper>
        <Form.Control name={name} accepter={ActivitiesInputControl} />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default ActivitiesFields;
