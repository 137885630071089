import { AxiosRequestConfig } from 'axios';
import { SclaApplication } from 'features/scla/types';
import { makePostRequest } from 'lib/axios';

const uploadSclaStudentSignature = async (
  id: string,
  payload: any,
): Promise<SclaApplication> => {
  // headers for server query
  const config: AxiosRequestConfig = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const url = `/application/signature/student/${id}`;
  const response = await makePostRequest(url, payload, config);
  return response.data.payload;
};

export default uploadSclaStudentSignature;
