/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

type LoaderProps = {
  isVisible: boolean;
  fullscreen: boolean;
  theme: 'dark' | 'light';
};

const outerAnimation = keyframes`
from {transform: rotate(0deg); }
30% { transform: rotate(0deg);}
to { transform: rotate(360deg); }
`;
const innerAnimation = keyframes`
from {transform: rotate(0deg); }
30% { transform: rotate(0deg);}
to { transform: rotate(360deg); }
`;

const StyledOuterContainer = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: space-around;
  justify-content: space-between;
  gap: 16px;
  animation: ${outerAnimation} 2000ms ease-in-out 200ms infinite;
`;

const StyledInnerContainer = styled.div`
  position: absolute;
  display: grid;
  gap: 4px;
  grid-template-columns: 1fr 1fr;
  align-content: space-around;
  justify-content: space-between;
  animation: ${innerAnimation} 2000ms ease-in-out infinite;
`;

const StyledInner = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f8961e;
`;

const StyledOuter = styled.div`
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: #43aa8b;
`;

const StyledCenter = styled.div`
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
`;

const StyledInnerWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: rotate(45deg);
`;

const StyledOuterWrapper = styled.div<LoaderProps & { hide: boolean }>`
  display: ${props => (props.hide ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
  background-color: ${props =>
    props.theme === 'dark' ? '#282828' : 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', sans-serif;
  color: white;
  opacity: ${props => (props.isVisible ? '1' : '0')};
  transition: all ease-in-out 300ms;
  z-index: 9;
  flex: 1;
  position: ${props => (props.fullscreen ? 'absolute' : 'relative')};
  left: 0;
  top: 0;
  z-index: 9999999999;
`;

export default function Loader(props: LoaderProps) {
  const { isVisible } = props;
  const [hide, setHide] = useState(false); // loader is visible when hide = false

  /**
   * Check isVisible every time it
   * changes. If isVisible = false, set
   * a timer to hide the loader
   */
  useEffect(() => {
    let mounted = true;
    let timer1: ReturnType<typeof setTimeout>;

    if (!isVisible && mounted) {
      timer1 = setTimeout(() => {
        setHide(true);
      }, 400);
    }

    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      mounted = false;
      if (timer1) clearTimeout(timer1);
    };
  }, [isVisible, setHide]);

  return (
    <StyledOuterWrapper {...props} hide={hide}>
      <StyledInnerWrapper>
        <StyledCenter />

        <StyledInnerContainer>
          <StyledInner />
          <StyledInner />
          <StyledInner />
          <StyledInner />
        </StyledInnerContainer>

        <StyledOuterContainer>
          <StyledOuter />
          <StyledOuter />
          <StyledOuter />
          <StyledOuter />
        </StyledOuterContainer>
      </StyledInnerWrapper>
    </StyledOuterWrapper>
  );
}
