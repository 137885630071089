import { getAuth, signOut } from 'firebase/auth';
import serverErrorHandler from 'services/serverError.service';
import { dispatchLogout } from 'store/dispatcher';

// handles user logout
export default function useLogout() {
  const logout = async () => {
    try {
      const auth = getAuth();
      await signOut(auth);
      dispatchLogout();
    } catch (e) {
      serverErrorHandler(e);
    }
  };
  return { logout };
}
