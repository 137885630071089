import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userSessionReducer from './slices/userSessionSlice';
import sideMenuReducer from './slices/sideMenuSlice';

/**
 * Create redux store by combing all the reducer slices
 */
export const store = configureStore({
  reducer: {
    currentUser: userSessionReducer, // Reducer to handle user session data
    sideMenu: sideMenuReducer, // Reducer to handle side menu state
  },
  devTools: process.env.NODE_ENV === 'development' // only enable in dev
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
