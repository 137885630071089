import { makePostRequest } from 'lib/axios';

const forgotPassword = async (payload: {
  email: string;
}): Promise<string> => {
  const url = `/auth/forgot-password`;
  const response = await makePostRequest(url, payload);
  return response.data.message;
};

export default forgotPassword;
