import React from 'react';
import CustomForm from 'components/Form/CustomForm';
import useScrollToTop from 'hooks/useScrollToTop';
import styled from 'styled-components';
import { SclaApplication } from '../types';
import useUpdateSclaReturnerAppPrompts from '../hooks/returner-app/useUpdateSclaReturnerAppPrompts';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px 16px 50px 16px;
`;

export default function ReturnerAppPromptsForm(props: {
  application: SclaApplication;
  setApplication: (app: SclaApplication) => void;
}) {
  useScrollToTop();
  const { application, setApplication } = props;

  const { formValue, isLoading, model, fields, onChange, onSubmit } =
    useUpdateSclaReturnerAppPrompts(application, setApplication);

  return (
    <Container>
      <CustomForm
        onChange={onChange}
        onSubmit={onSubmit}
        fields={fields}
        isLoading={isLoading}
        model={model}
        formValue={formValue}
        isDisabled={false}
        submitLabel="Save and Continue"
        hasChanges={false}
      />
    </Container>
  );
}
