import styled from 'styled-components';
import React from 'react';
import CustomForm from 'components/Form/CustomForm';
import Button from 'components/Elements/Button/Button';
import { NavLink, useNavigate } from 'react-router-dom';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import useInterests from '../hooks/useIntersts';

const Container = styled.div`
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;

  @media (min-width: 768px) {
    max-width: 400px;
  }

  @media (min-width: 992px) {
    max-width: 400px;
  }
`;


const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const CustomLink = styled(NavLink)`
  p {
    font-size: 15px;
    color: var(--color-blue);

    @media (min-width: 992px) {
      &:hover {
        color: var(--color-black);
      }
    }
  }
`;

function InterestsForm() {
  // get sign in form state
  const {
    isComplete,
    fields,
    model,
    formValue,
    isLoading,
    onSubmit,
    onChange,
  } = useInterests();

  const navigate = useNavigate();

  const qFirstName = `firstName=${encodeURIComponent(
    formValue.firstName,
  )}`;
  const qLastName = `&lastName=${encodeURIComponent(
    formValue.lastName,
  )}`;
  const qEmail = `&email=${formValue.email}`;
  const qPhoneNumber = `&phoneNumber=${formValue.phoneNumber}`;
  const qSchool = `&schoolName=${encodeURIComponent(
    formValue.schoolName,
  )}&phoneNumber=${formValue.phoneNumber}`;
  const qGrade = `&gradeLevel=${formValue.gradeLevel}`;
  const qEthnicity = `&ethnicity=${encodeURIComponent(
    formValue.ethnicity,
  )}`;
  const qEthnicityOther = `&ethnicityOther=${encodeURIComponent(
    formValue.ethnicityOther,
  )}`;

  const signUpUrl = `/sign-up?${qFirstName}${qLastName}${qEmail}${qPhoneNumber}${qSchool}${qGrade}${qEthnicity}${qEthnicityOther}`;


  if (isComplete) {
    return (
      <Container>
        <p>
          Thank you for expressing interest in the Summer Coding Leadership
          Academy (SCLA).
        </p>
        <p>Please click the button below to get started on your application.</p>

        <Button
          style={{marginTop: 20}}
          size="full"
          type="submit"
          color="green"
          isLoading={isLoading}
          onClick={()=> navigate(signUpUrl)}
        >
          <ParagraphText textAlign="center" margin={0} color="#fff">
            Get Started
          </ParagraphText>
        </Button>
      </Container>
    );
  }

  return (
    <Container>
      <p>
        Please fill out the information below so we can help you get
        started on your application.
      </p>
      <CustomForm
        onChange={onChange}
        onSubmit={onSubmit}
        model={model}
        formValue={formValue}
        isLoading={isLoading}
        fields={fields}
        isDisabled={false}
        submitLabel="Submit"
        hasChanges={false}
        submitBtnSize="full"
      />
            <LinksContainer>
        <CustomLink to="/">
          <p>Go to log in.</p>
        </CustomLink>
      </LinksContainer>
    </Container>
  );
}

export default InterestsForm;
