import { IUpdateSclaAppBackground, SclaApplication } from 'features/scla/types';
import { makePutRequest } from 'lib/axios';

const updateSclaAppBackground = async (
  id: string,
  payload: IUpdateSclaAppBackground,
): Promise<SclaApplication> => {
  const url = `/application/background/${id}`;
  const response = await makePutRequest(url, payload);
  return response.data.payload;
};

export default updateSclaAppBackground;
