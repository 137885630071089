
export const getLoginPath= () => '/';

export const getHomePath = () => '/home';

export const getSclaAppBackgroundPath = () => '/application/background';
export const getSclaAppSchoolPath = () => '/application/school';
export const getSclaAppFamilyPath = () => '/application/family';
export const getSclaAppPromptsPath = () => '/application/prompts';
export const getSclaAppTranscriptsPath = () => '/application/transcripts';
export const getSclaAppRecPath = () => '/application/rec';
export const getSclaAppSignaturePath = () => '/application/signature';

export const getUpdateProfilePath = () => '/profile';
