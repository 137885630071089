import React, { Suspense, lazy } from 'react';
import Loader from 'components/Elements/Loader/Loader';
import { MainLayout } from 'components/Layout/MainLayout';
import { Navigate, Outlet } from 'react-router-dom';
import UserSessionProvider from 'providers/UserSessionProvider';
import { SclaApplicationRoutes } from 'features/scla/routes';

const HomeRoutes = lazy(() => import('features/home/routes'));
const ProfileRoutes = lazy(() => import('features/profile/routes'));

/**
 * Renders the routes. A loader is shown if user data is being fetched.
 * A loader is shown for routes that are lazy loaded
 * @returns
 */
function App() {
  return (
    <UserSessionProvider>
      {({ user }) => (
        <MainLayout user={user}>
          <Suspense
            fallback={
              <Loader theme="light" isVisible fullscreen={false} />
            }
          >
            <Outlet context={{ user }} />
          </Suspense>
        </MainLayout>
      )}
    </UserSessionProvider>
  );
}

const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'home/*', element: <HomeRoutes /> },
      { path: 'profile/*', element: <ProfileRoutes /> },
      { path: 'application/*', element: <SclaApplicationRoutes /> },
      {
        path: '',
        element: <Navigate to="/home" />,
      },
      {
        path: '*',
        element: <Navigate to="/home" />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/home" />,
  },
];

export default protectedRoutes;
