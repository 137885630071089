import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import {
  COLOR_PRIMARY,
  COLOR_GREEN,
  COLOR_GOLD,
  COLOR_RED,
} from 'constants/colors';
import Button from 'components/Elements/Button/Button';

export type AlertType = 'info' | 'warn' | 'danger' | 'success';

/**
 * Returns color for the program alert
 * @param type
 * @returns
 */
const getColor = (type: AlertType) => {
  if (type === 'info') return COLOR_PRIMARY;

  if (type === 'success') return COLOR_GREEN;

  if (type === 'warn') return COLOR_GOLD;

  return COLOR_RED;
};

const IconContainer = styled.div`
  flex: 0 0 25px;
  justify-content: center;
  display: flex;
  svg {
    font-size: 1rem;
    flex: 0 0 1rem;
    text-align: center;
  }
`;

const AlertContainer = styled.div<{ type: AlertType }>`
  display: flex;
  flex-direction: column;
  border-left: solid 12px ${props => getColor(props.type)};
  background-color: #f4f5f7;
  border-radius: 4px;
  overflow: hidden;
  padding: 8px 16px;
  gap: 12px;

  ${IconContainer} {
    svg {
      color: ${props => getColor(props.type)};
    }
  }
`;

const AlertMessageContainer = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const UpperContainer = styled.div`
  display: flex;
`;

const ActionButton = styled(Button)`
  p {
    color: #fff;
  }
`;

interface IProgramAlert {
  button?: {
    label: string;
    onClick: () => void;
  };
  type: AlertType;
  children: React.ReactNode;
}

export interface ProgramAlertIconMap {
  [key: string]: React.ReactNode; // Index signature for string keys
}

const iconMap: ProgramAlertIconMap = {
  warn: <FontAwesomeIcon icon={faTriangleExclamation} />,
  info: <FontAwesomeIcon icon={faCircleInfo} />,
  danger: <FontAwesomeIcon icon={faCircleExclamation} />,
  success: <FontAwesomeIcon icon={faCircleCheck} />,
};

export default function ProgramAlert(props: IProgramAlert) {
  const { button, type, children } = props;

  return (
    <AlertContainer type={type}>
      <UpperContainer>
        <IconContainer>{iconMap[type]}</IconContainer>
        <AlertMessageContainer>{children}</AlertMessageContainer>
      </UpperContainer>
      <ButtonContainer>
        {button && (
          <ActionButton onClick={button.onClick} type="button" size="sm">
            <p>{button.label}</p>
          </ActionButton>
        )}
      </ButtonContainer>
    </AlertContainer>
  );
}

ProgramAlert.defaultProps = {
  button: undefined,
};
