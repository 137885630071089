import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faHouse,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import useNavigationLinks, {
  DropdownLink,
  NavigationLink,
} from 'hooks/useNavigationLinks';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR_BLACK, COLOR_LIGHT_BLUE } from 'constants/colors';
import { getSideMenuStatus } from 'store/slices/sideMenuSlice';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import useLogout from 'hooks/useLogout';
import { getHomePath, getUpdateProfilePath } from 'utils/navigation';
import { dispatchSideMenuToggle } from 'store/dispatcher';
import useIsMobile from 'hooks/useIsMobile';
import { UserSession } from 'types';
import { enablePageScroll, preventPageScroll } from 'utils/scrollOverlay';
import { useAppSelector } from '../../../store/hooks';
import SideNavLink from '../SideNavLink';
import SideNavHeader from './SideNavHeader';

export interface SideNavLinkProps {
  link: NavigationLink; // link to display
  toggleLinkDropdown: (link: DropdownLink) => void; // open link dropdown
  closeSideNav: () => void;
}

const SideNavContainer = styled.div<{ isOpen: boolean }>`
  padding-top: 80px;
  position: fixed;
  left: ${props => (props.isOpen ? '0px' : '-100vw')};
  top: 0;
  background-color: ${COLOR_BLACK};
  width: 100vw;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  transition: left ease-in 300ms;
  z-index: 99;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    width: 256px;
    left: ${props => (props.isOpen ? '0px' : '-256px')};
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1.25rem;
  position: relative;
`;

const MenuWrapper = styled.div`
  margin: 0 -1.25rem -1.25rem;
  padding-bottom: 75px;

  ul {
    margin: 0;
    padding: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    user-select: none;

    li {
      margin: 0.5px 0;
    }
  }
`;

const TriggerText = styled(ParagraphText)`
  font-size: 16px;
  color: #fff;
  margin: 0;
`;

const ButtonTrigger = styled.button`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
    color: #fff;
  }

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};

    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }

    svg {
      color: ${COLOR_LIGHT_BLUE};
    }
  }
`;

const SingleLinkTrigger = styled(NavLink)`
  width: 100%;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 0.75rem 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;

  svg {
    font-size: 1rem;
    margin: 0 0.5rem 0 0;
    flex: 0 0 1rem;
    text-align: center;
    color: #fff;
  }

  &:focus,
  &:hover {
    color: ${COLOR_LIGHT_BLUE};

    ${TriggerText} {
      color: ${COLOR_LIGHT_BLUE};
    }

    svg {
      color: ${COLOR_LIGHT_BLUE};
    }
  }

  &.active {
    background-color: ${COLOR_LIGHT_BLUE};
    &:focus,
    &:hover {
      color: #fff;
      ${TriggerText} {
        color: #fff;
      }

      svg {
        color: #fff;
      }
    }
  }
`;

const Section = styled.div`
  p {
    color: #58bcea;
    text-transform: uppercase;
    font-size: 15px;
    padding: 0 5px;
    margin: 10px 0px;
  }
`;

/**
 * Side menu that contains all the page links
 * Links are shown based on the user account type
 * @param param0
 * @returns
 */
function SideNav({ user }: { user: UserSession }) {
  const { links, toggleLinkDropdown } = useNavigationLinks();

  const isOpen = useAppSelector(getSideMenuStatus);

  const { logout } = useLogout();

  const { isMobile } = useIsMobile();

  const closeSideNav = () => {
    if (isMobile) dispatchSideMenuToggle();
  };

  useEffect(() => {
    if (isOpen && isMobile) preventPageScroll();
    else enablePageScroll();
  }, [isOpen]);

  return (
    <SideNavContainer isOpen={isOpen}>
      <SideNavHeader user={user} />
      <ScrollContainer>
        <MenuWrapper>
          <ul>
            <li aria-hidden="true" onClick={closeSideNav}>
              <SingleLinkTrigger to={getHomePath()} end>
                <div>
                  <FontAwesomeIcon icon={faHouse} />
                </div>

                <TriggerText>Home</TriggerText>
              </SingleLinkTrigger>
            </li>

            <li>
              <Section>
                <p> ... Application </p>
              </Section>
            </li>

            {links.map((link, i) => (
              <SideNavLink
                key={i}
                link={link}
                toggleLinkDropdown={toggleLinkDropdown}
                closeSideNav={closeSideNav}
              />
            ))}

            <li>
              <Section>
                <p>... other</p>
              </Section>
            </li>
            <SideNavLink
              link={{
                type: 'single',
                data: {
                  title: 'Update Profile',
                  icon: <FontAwesomeIcon icon={faCog} />,
                  to: getUpdateProfilePath(),
                  type: 'internal',
                },
              }}
              toggleLinkDropdown={toggleLinkDropdown}
              closeSideNav={closeSideNav}
            />

            <li>
              <ButtonTrigger type="button" onClick={logout}>
                <div>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
                <TriggerText>Sign Out</TriggerText>
              </ButtonTrigger>
            </li>
          </ul>
        </MenuWrapper>
      </ScrollContainer>
    </SideNavContainer>
  );
}

export default SideNav;
