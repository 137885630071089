import React from 'react';
import CustomForm from 'components/Form/CustomForm';
import { useOutletContext, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import useScrollToTop from 'hooks/useScrollToTop';
import { COLOR_GREEN } from 'constants/colors';
import Alert from 'components/Elements/Alert';
import { SclaApplicationOutletContext } from './SclaAppLayout';
import useUpdateSclaAppBackground from '../hooks/useUpdateSclaAppBackground';
import { getSclaAppFamilyPath } from '../../../utils/navigation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 6px;
  padding: 20px 16px 50px 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h1 {
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const NavButtonLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  background-color: ${COLOR_GREEN};
  padding: 10px 15px;
  border-radius: 4px;
  p {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
  }
`;
export default function SclaApplicationBackground() {
  useScrollToTop();
  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  const { formValue, isLoading, model, fields, onChange, onSubmit } =
    useUpdateSclaAppBackground(application, setApplication);

  return (
    <>
      <Header>
        <h1>Background Information</h1>
        <NavButtons>
          <NavButtonLink to={getSclaAppFamilyPath()}>
            <p>Next</p>
          </NavButtonLink>
        </NavButtons>
        <Alert type="info">
          <p>
            Due to the nature of our application, we make some fields
            `required`.
          </p>
          <p>
            You can omit information for these fields by typing in `N/A`.
          </p>
        </Alert>
      </Header>

      <Container>
        <CustomForm
          onChange={onChange}
          onSubmit={onSubmit}
          fields={fields}
          isLoading={isLoading}
          model={model}
          formValue={formValue}
          isDisabled={false}
          submitLabel="Save and Continue"
          hasChanges={false}
        />
      </Container>
    </>
  );
}
