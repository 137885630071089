import styled from 'styled-components';
import logoImage from 'assets/images/logo-color.png';
import React from 'react';

const Image = styled.img`
  width: 300px;
`;

export default function Logo() {
  return <Image src={logoImage} alt="TXT logo" />
}
