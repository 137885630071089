import React from 'react';
import { UserSession } from 'types';

export function getProfileImageUrl(
  _id: string,
  profileImage: string | null,
): string {
  if (profileImage && profileImage !== '') {
    return profileImage;
  }
  return `https://robohash.org/${_id}`;
}
export default function ProfileImage({ user }: { user: UserSession }) {
  const { _id, profileImage } = user;

  return (
    <img src={getProfileImageUrl(_id, profileImage)} alt="user profile" />
  );
}
