const RAW_SCHOOL_LIST = [
  '32nd Street / USC Performing Arts Magnet',
  'Alliance Renee & Meyer Luskin College-Ready Academy',
  'Alliance Patti & Peter Neuwirth Leadership Academy',
  'Alliance Ted K. Tajima High School',
  "Alliance Ouchi-O'Donovan 6-12 Complex",
  'Ánimo Inglewood Charter High School',
  'Ánimo Pat Brown Charter High School',
  'Ánimo South LA Charter High School',
  'Ánimo Watts College Preparatory Academy',
  'Aspire Ollin University Preparatory Academy',
  'Compton Early College High School',
  'Susan Miller Dorsey Senior High School',
  'John C. Fremont High School',
  'Hawthorne Math and Science Academy',
  'Huntington Park Senior High & STEAM Magnet',
  'Alain Leroy Locke College Preparatory Academy',
  'Dr Maya Angelou Community Senior High',
  'Orthopaedic Medical Magnet High School',
  'Synergy Quantum Academy',
  'USC Hybrid High College Prep',
  'ICEF View Park Preparatory Accelerated Charter High School',
];

const SCHOOL_LIST = RAW_SCHOOL_LIST.filter(
  (value, index, self) => self.indexOf(value) === index,
);

export default SCHOOL_LIST;
