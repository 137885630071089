import { useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import {
  getFormValidationStatusAsync,
  scrollToFormErr,
} from 'utils/form.util';
import formValidation from 'constants/validation';
import { FormField } from 'components/Form/CustomForm';
import { openInfoToaster } from 'services/toast.service';
import useAppNavigation from 'hooks/useAppNavigation';
import forgotPassword from '../api/forgotPassword';

export interface IForgotPasswordForm {
  email: string;
}

const { errorMessages: err } = formValidation;

// Extract schema types for form validation
const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType().isRequired(err.email).isEmail(err.email),
});

const INIT_FORM: IForgotPasswordForm = {
  email: '',
};

const fields: FormField[] = [
  {
    name: 'email',
    type: 'email',
    label: 'Email',
    size: 'lg',
    data: null,
  },
];

export default function useForgotPassword() {
  const [formValue, setFormValue] = useState<IForgotPasswordForm>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process

  const {navToLogin} = useAppNavigation();


  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const onSubmit = async () => {
    try {
      const isValid = await getFormValidationStatusAsync(
        model.check(formValue),
      );
      // verify that form is valid
      if (isValid) {
        setIsLoading(true)

       const message:string =  await forgotPassword(formValue);

       openInfoToaster(message, 5000);

        setIsLoading(false);

        navToLogin();
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: any) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
