import { useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import {
  getFormValidationStatusAsync,
  scrollToFormErr,
} from 'utils/form.util';
import { FormField } from 'components/Form/CustomForm';
import { openSuccessToaster } from 'services/toast.service';
import useAppNavigation from 'hooks/useAppNavigation';
import { getAuth, confirmPasswordReset } from 'firebase/auth';

export interface IResetPasswordForm {
  password: string;
  passwordConfirm: string;
  userId: string;
  token: string;
}

// Extract schema types for form validation
const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType()
    .isRequired('Please enter a valid password.')
    .minLength(6, 'Password must be 6 characters long.'),
  passwordConfirm: StringType()
    .isRequired('Please re-enter your password.')
    .addRule((value, data) => {
      if (value !== data.password) {
        return false;
      }
      return true;
    }, 'Passwords do not match.'),
});

const INIT_FORM = {
  password: '',
  passwordConfirm: '',
  userId: '',
  token: '',
};

const fields: FormField[] = [
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    size: 'lg',
    data: null,
  },
  {
    name: 'passwordConfirm',
    type: 'password',
    label: 'Confirm Password',
    size: 'lg',
    data: null,
  },
];

export default function useResetPassword(token: string) {
  const [formValue, setFormValue] =
    useState<IResetPasswordForm>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process

  const { navToLogin } = useAppNavigation();

  /**
   * Makes server request to validate user credentials.
   * Users with valid credentials are authenticated and
   * redirect to the authenticated routes
   */
  const onSubmit = async () => {
    try {
      const isValid = await getFormValidationStatusAsync(
        model.check(formValue),
      );
      // verify that form is valid
      if (isValid) {
        setIsLoading(true);

        const auth = getAuth();
        await confirmPasswordReset(auth, token || '', formValue.password);
        openSuccessToaster('Password reset.', 3500);
        setIsLoading(false);

        navToLogin();
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: any) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
