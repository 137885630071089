/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { Checkbox, CheckboxGroup, Form } from 'rsuite';
import { FormFieldSize } from 'components/Form/CustomForm';
import styled from 'styled-components';
import FieldLabel from '../../Control/FormControlLabel';

export interface ICheckboxData {
  label: string;
  value: any;
}

interface ICheckboxFormField {
  name: string; // name of the key to map to
  helpMessage?: string; // help message to display
  label: string; // field label
  checkboxes: ICheckboxData[]; // checkboxes to render
  className?: any; // custom class name for form field group
}

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
    flex: 1 0 100%;
  @media (min-width: 992px){
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

/**
 * Renders a checkbox
 */
const CheckboxFormField = forwardRef(
  (props: ICheckboxFormField, ref: any) => {
    const { checkboxes, name, helpMessage, label, ...rest } = props;

    return (
      <FormGroup ref={ref} size="lg">
        <FieldLabel>{label} </FieldLabel>
        <ControlWrapper>
          <Form.Control
            style={{ width: '100%', height: 38 }}
            accepter={CheckboxGroup}
            name={name}
            {...rest}
          >
            {checkboxes.map((c, i) => (
              <Checkbox key={i} value={c.value}>
                {c.label}
              </Checkbox>
            ))}
          </Form.Control>
          {helpMessage ? (
            <Form.HelpText>{helpMessage}</Form.HelpText>
          ) : null}
        </ControlWrapper>
      </FormGroup>
    );
  },
);

export default CheckboxFormField;

CheckboxFormField.defaultProps = {
  helpMessage: undefined,
  className: undefined,
};
