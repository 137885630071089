import styled from 'styled-components';
import React from 'react';
import { TextStyleProps } from 'types';

type TextProps = { children: string | string[]; className?: string } & TextStyleProps;

const StyledHeader = styled.h1<TextProps>`
color: ${props => props.color || '#000'};
font-weight: ${props => props.fontWeight || '500'};
text-align: ${props => props.textAlign || 'left'};
font-size: ${props => (props.fontSize !== undefined ? `${props.fontSize}px` : '26px')};
line-height: ${props => (props.lineHeight !== undefined ? `${props.lineHeight}` : '17px')};
font-family: 'Roboto', 'sans-serif';

  ${props =>
    props.margin !== undefined
      ? `margin:${props.margin}px;`
      : `
      margin-bottom: ${props.marginBottom !== undefined ? `${props.marginBottom}px` : '0.5rem'};
      margin-top: ${props.marginTop !== undefined ? `${props.marginTop}px` : '.5rem'};
      margin-left: ${props.marginLeft !== undefined ? `${props.marginLeft}px` : '.5rem'};
      margin-right: ${props.marginRight !== undefined ? `${props.marginRight}px` : '.5rem'};
      `}

  ${props =>
    props.padding !== undefined
      ? `padding:${props.padding}px;`
      : `
          padding-bottom: ${props.paddingBottom !== undefined ? `${props.paddingBottom}px` : '0'};
          padding-top: ${props.paddingTop !== undefined ? `${props.paddingTop}px` : '0'};
          padding-left: ${props.paddingLeft !== undefined ? `${props.paddingLeft}px` : '0'};
          padding-right: ${props.paddingRight !== undefined ? `${props.paddingRight}px` : '0'};
              `}
`;

export default function Header({ children, className }: TextProps) {
  return <StyledHeader className={className}>{children}</StyledHeader>
}

Header.defaultProps = {
  className: undefined
}