import { TAG_COLOR_GREEN, TAG_COLOR_BLUE, TAG_COLOR_RED } from 'constants/colors';
import React from 'react';
import { Tag } from 'rsuite';
import styled from 'styled-components';
import { MyTxtUserType } from 'types';

export interface IUserTypeTagProps {
  type: MyTxtUserType;
  size: 'lg' | 'md' | 'sm' | undefined;
}

const getColor = (type: MyTxtUserType) => {
  if (type === 'txter') return TAG_COLOR_GREEN;

  if (type === 'alumni') return TAG_COLOR_BLUE;

  return TAG_COLOR_RED;
};

const CustomTag = styled(Tag)`
  text-transform: lowercase;
  color: #fff;
  background-color: ${props => getColor(props.type)};
`;

export function UserTypeTag(props: IUserTypeTagProps) {
  const { type, size } = props;

  return (
    <CustomTag type={type} size={size}>
      {type}
    </CustomTag>
  );
}
