import React from 'react';
import Loader from 'components/Elements/Loader/Loader';
import { UserSession } from 'types';
import { getUser } from '../store/slices/userSessionSlice';
import { useAppSelector } from '../store/hooks';

export default function UserSessionProvider({
  children,
}: {
  children: ({ user }: { user: UserSession }) => React.ReactNode;
}) {
  // fetch profile data from store
  const user = useAppSelector(getUser);

  if (user) {
    return <div>{children({ user })}</div>;
  }

  return <Loader isVisible fullscreen={false} theme="dark" />;
}
