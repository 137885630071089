import AppProvider from 'providers/app';
import AppRoutes from 'routes';
import React from 'react';
import StoreProvider from 'providers/store';

function App() {
  // render routes
  return (
    <StoreProvider>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </StoreProvider>
  );
}

export default App;
