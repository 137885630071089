import Logo from 'components/Images/Logo';
import React from 'react';
import styled from 'styled-components';
import useScrollToTop from 'hooks/useScrollToTop';
import InterestsForm from '../components/InterestsForm';

const PageContainer = styled.div`
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 50px 25px;
  position: fixed;

  @media (min-width: 768px) {
    padding: 100px 25px;
  }
`;

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-bottom: 50px;

  @media (min-width: 768px) {
    width: 400px;
  }
`;

const FormHeader = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const LogoContainer = styled.div`
  img {
    width: 100px;
  }

  @media (min-width: 500px) {
    img {
      width: 175px;
    }
  }
`;


function Interests() {
  useScrollToTop();
  return (
    <PageContainer>
      <Container>
        <FormHeader>
          <LogoContainer>
            <Logo />
          </LogoContainer>
        </FormHeader>

        <InterestsForm />
      </Container>
    </PageContainer>
  );
}

export default Interests;
