/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import { Form, MaskedInput } from 'rsuite';
import { FormFieldSize } from 'components/Form/CustomForm';
import styled from 'styled-components';
import FormControlLabel from 'components/Form/Control/FormControlLabel';

const FormGroup = styled(Form.Group)<{ ref: any; size: FormFieldSize }>`
    flex: 1 0 100%;
  @media (min-width: 992px){
    flex: 0 1 ${props => (props.size === 'sm' ? '50%' : '100%')};
  }

  .rs-input{
    font-size: 16px;
  }
`;

const ControlWrapper = styled.div`
  margin: 0.5rem;
`;

const config = {
  name: 'US phone number',
  mask: [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  placeholder: '(555) 495-3947',
};

const PhoneNumberFormField = forwardRef((props: any, ref: any) => {
  const { required, size, name, helpMessage, label,} = props;
  const showRequired: boolean = required || false;
  return (
    <FormGroup ref={ref} size={size}>
      <FormControlLabel required={showRequired}>{label} </FormControlLabel>
      <ControlWrapper>
        <Form.Control
          name={name}
          accepter={MaskedInput}
          mask={config.mask}
          guide
          keepCharPositions
          type="tel"
        />
        {helpMessage ? <Form.HelpText>{helpMessage}</Form.HelpText> : null}
      </ControlWrapper>
    </FormGroup>
  );
});

export default PhoneNumberFormField;

PhoneNumberFormField.defaultProps = {
  placeholder: undefined,
};
