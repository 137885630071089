import React from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import Alert from 'components/Elements/Alert';
import FileUploader from 'components/Elements/FileUploader/FileUploader';
import { url } from 'constants/api.constants';
import { SclaApplicationOutletContext } from './SclaAppLayout';
import useUpdateSclaAppLetterOfRec from '../hooks/useUpdateSclaAppLetterOfRec';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h1 {
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
  }
`;

const Description = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  align-items: flex-start;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    font-size: 24px;
    width: 100%;
    text-align: left;
  }
`;

const AlertContainer = styled.div`
  width: 100%;
  margin: 0 auto 20px auto;

  @media (min-width: 768px) {
    width: 400px;
  }
`;

export default function SclaApplicationLetterOfRec() {
  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  const { files, isLoading, onRemove, onError, onSuccess, setIsLoading } =
    useUpdateSclaAppLetterOfRec(application, setApplication);

  return (
    <>
      <Header>
        <h1>Letter of Recommendation</h1>
      </Header>

      <AlertContainer>
        <Alert type="info">
          <p>
            The letter of recommendation is optional and is not required.
          </p>
        </Alert>
      </AlertContainer>

      <Description>
        <h5>What is a letter of recommendation?</h5>
        <p>
          A letter of recommendation is a formal document that addresses
          your suitability and qualifications for a particular position.
          You can use these documents when pursuing a new job, applying to
          attend a university or earn a scholarship or seeking a leadership
          position for a group or organization.
        </p>
        <p>
          <a
            href="https://txt-applications.s3.amazonaws.com/scla_2023_letter_of_rec+(1).pdf"
            target="_blank"
            rel="noreferrer"
          >
            Download the template here.
          </a>
        </p>
      </Description>
      <div>
        <FileUploader
          setIsLoading={setIsLoading}
          files={files}
          isLoading={isLoading}
          onRemove={onRemove}
          onError={onError}
          onSuccess={onSuccess}
          url={`${url.API_URL}/application/rec/${application._id}`}
        />
      </div>
    </>
  );
}
