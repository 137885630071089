export const preventPageScroll = () => {
  const body = document.querySelector('body')
  if (body) {
    body.style.overflow = 'hidden'
  }
}

export const enablePageScroll = () => {
  const body = document.querySelector('body')
  if (body) {
    body.style.removeProperty('overflow')
  }
}
  