/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Returns a boolean value. True if the form values do not have
 * any validation errors
 *
 * @param validationState The form validation obj which contains errors if they exist
 * @returns Boolean value
 */
export const getFormValidationStatus = (validationState: any): boolean =>
  Object.values(validationState).every(
    (payload: any) => !payload.hasError,
  );
  
export const getFormValidationStatusAsync = (
  validationState: any,
): Promise<boolean> => new Promise(resolve => {
    const isValid = Object.values(validationState).every(
      (payload: any) => !payload.hasError,
    );

    // Simulating asynchronous behavior with a short delay
    setTimeout(() => {
      resolve(isValid);
    }, 0);
  });

/**
 * Checks if provided grade value is in college
 * @param grade
 * @returns
 */
export const isInCollege = (grade: string) => {
  if (grade === 'freshman') return true;
  if (grade === 'sophomore') return true;
  if (grade === 'junior') return true;
  if (grade === 'senior') return true;
  return false;
};

export const hasGraduated = (grade: string) => {
  if (grade === 'graduated') return true;
  return false;
};

export const isInMiddleSchool = (grade: string) => {
  if (grade.includes('5')) return true;
  if (grade.includes('6')) return true;
  if (grade.includes('7')) return true;
  if (grade.includes('8')) return true;
  return false;
};

export const isInHighSchool = (grade: string) => {
  if (grade.includes('9')) return true;
  if (grade.includes('10')) return true;
  if (grade.includes('11')) return true;
  if (grade.includes('12')) return true;
  return false;
};


export function handleNullString(str: string | null): any {
  if (str) return str;
  return '';
}

/**
 * Scrolls user to errors on the form
 */
export const scrollToFormErr = () => {
  const element = document.querySelectorAll('.rs-form-error-message-show');

  if (element && element[0]) {
    // scroll to error input
    element[0].scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};
