import { useEffect, useState } from 'react';
import serverErrorHandler from 'services/serverError.service';
import { FileType } from 'rsuite/esm/Uploader';
import { openSuccessToaster } from 'services/toast.service';
import removeSclaAppTranscripts from 'features/scla/api/removeSclaAppTranscripts';
import { SclaApplication } from 'features/scla/types';

export default function useUpdateSclaAppTranscripts(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState<FileType[]>([]);

  useEffect(() => {
    const { transcriptsInfo } = app;

    setFiles(
      transcriptsInfo.files.map((item: any) => ({
        name: item.originalName,
        url: item.url,
        fileKey: item._id,
        status: 'finished',
        progress: 100,
      })),
    );
  }, [app]);

  const onError = (e: any) => {
    if (e.response) {
      serverErrorHandler({ response: { data: e.response } });
    } else {
      serverErrorHandler(e);
    }
    setIsLoading(false);
  };

  const onSuccess = (response: any) => {
    setApplication({
      ...app,
      transcriptsInfo: response.payload.transcriptsInfo,
    });
    openSuccessToaster('File uploaded.', 3000);
    setIsLoading(false);
  };

  const onRemove = async (file: FileType) => {
    try {
      setIsLoading(true);
      const response = await removeSclaAppTranscripts(app._id, {
        fileId: `${file.fileKey}`,
      });

      setApplication({
        ...app,
        transcriptsInfo: response.transcriptsInfo,
      });
      setIsLoading(false);
      openSuccessToaster(`Removed file.`, 3000);
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  return {
    isLoading,
    files,
    onError,
    onSuccess,
    onRemove,
    setIsLoading
  };
}
