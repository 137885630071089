/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import { TextStyleProps } from 'types';
import React from 'react';

type TextProps = { children: string | string[] } & TextStyleProps;

const StyledText = styled.p<TextProps>`
  color: ${props => props.color || '#000'};
  font-weight: ${props => props.fontWeight || '400'};
  text-align: ${props => props.textAlign || 'left'};
  font-size: ${props => (props.fontSize !== undefined ? `${props.fontSize}px` : '16px')};
  font-family: 'Roboto', 'sans-serif';
  letter-spacing: ${props => props.letterSpacing !== undefined ? `${props.letterSpacing}px`: 'auto'};

  ${props =>
    props.margin !== undefined
      ? `margin:${props.margin}px;`
      : `
      margin-bottom: ${props.marginBottom !== undefined ? `${props.marginBottom}px` : '5.rem'};
      margin-top: ${props.marginTop !== undefined ? `${props.marginTop}px` : '5.rem'};
      margin-left: ${props.marginLeft !== undefined ? `${props.marginLeft}px` : '5.rem'};
      margin-right: ${props.marginRight !== undefined ? `${props.marginRight}px` : '5.rem'};
      `}

  ${props =>
    props.padding !== undefined
      ? `padding:${props.padding}px;`
      : `
      padding-bottom: ${props.paddingBottom !== undefined ? `${props.paddingBottom}px` : '0'};
      padding-top: ${props.paddingTop !== undefined ? `${props.paddingTop}px` : '0'};
      padding-left: ${props.paddingLeft !== undefined ? `${props.paddingLeft}px` : '0'};
      padding-right: ${props.paddingRight !== undefined ? `${props.paddingRight}px` : '0'};
          `}

  &:hover {
    color: ${props => (props.hoverColor ? props.hoverColor : props.color)};
  }
`;

export default function ParagraphText(props: TextProps) {
  const { children, ...rest } = props;

  return <StyledText {...rest}>{children}</StyledText>;
}
