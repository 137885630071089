import React from 'react';
import styled from 'styled-components';
import { StackProps } from 'types';

type HStackProps = { children: JSX.Element | JSX.Element[] } & StackProps;

const Stack = styled.div<HStackProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${props => (props.gap !== undefined ? `${props.gap}px` : '0')};

  ${props =>
    props.margin !== undefined
      ? `margin:${props.margin}px;`
      : `
      margin-bottom: ${
        props.marginBottom !== undefined ? `${props.marginBottom}px` : '0'
      };
      margin-top: ${
        props.marginTop !== undefined ? `${props.marginTop}px` : '0'
      };
      margin-left: ${
        props.marginLeft !== undefined ? `${props.marginLeft}px` : '0'
      };
      margin-right: ${
        props.marginRight !== undefined ? `${props.marginRight}px` : '0'
      };
      `}

  ${props =>
    props.padding !== undefined
      ? `padding:${props.padding}px;`
      : `
          padding-bottom: ${
            props.paddingBottom !== undefined
              ? `${props.paddingBottom}px`
              : '0'
          };
          padding-top: ${
            props.paddingTop !== undefined ? `${props.paddingTop}px` : '0'
          };
          padding-left: ${
            props.paddingLeft !== undefined
              ? `${props.paddingLeft}px`
              : '0'
          };
          padding-right: ${
            props.paddingRight !== undefined
              ? `${props.paddingRight}px`
              : '0'
          };
          `}

  justify-content: ${props =>
    props.justifyContent !== undefined
      ? `${props.justifyContent}`
      : 'flex-start'};
  align-items: ${props =>
    props.alignItems !== undefined ? `${props.alignItems}` : 'flex-start'};
`;

export default function HStack({ children, ...rest }: HStackProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Stack {...rest}>{children}</Stack>;
}
