import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import { getFormValidationStatus, scrollToFormErr } from 'utils/form.util';
import { FormField } from 'components/Form/CustomForm';
import {
  IUpdateReturnerSclaPrompts,
  SclaApplication,
} from 'features/scla/types';
import useAppNavigation from 'hooks/useAppNavigation';
import { openSuccessToaster } from 'services/toast.service';
import updateSclaReturnerAppPrompts from 'features/scla/api/returner/updateSclaReturnerAppPrompts';

// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  returnerSkillPrompt: StringType().isRequired(
    'Please answer this question.',
  ),
  returnerCuriosityPrompt: StringType().isRequired(
    'Please answer this question.',
  ),
  returnerCreedPrompt: StringType().isRequired(
    'Please answer this question.',
  ),
});

const INIT_FORM: IUpdateReturnerSclaPrompts = {
  returnerSkillPrompt: '',
  returnerCuriosityPrompt: '',
  returnerCreedPrompt: '',
};

const fields: FormField[] = [
  {
    name: 'returnerSkillPrompt',
    type: 'textarea',
    label: `How have you applied your skills gained through SCLA this past year in school and/or in life?`,
    size: 'lg',
    data: {
      rows: 20,
    },
    required: true,
  },

  {
    name: 'returnerCuriosityPrompt',
    type: 'textarea',
    label: `Tell us about a time curiosity got you in trouble.\nWhat was your main takeaway from that experience?`,
    size: 'lg',
    data: {
      rows: 20,
    },
    required: true,
  },
  {
    name: 'returnerCreedPrompt',
    type: 'textarea',
    label: `Talk about the leadership creed that you have
    applied most since joining TXT and why?`,
    size: 'lg',
    data: {
      rows: 20,
    },
    required: true,
  },
];

export default function useUpdateSclaReturnerAppPrompts(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const [formValue, setFormValue] =
    useState<IUpdateReturnerSclaPrompts>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process
  const { navToSclaAppTranscripts } = useAppNavigation();

  const mapDefaultVales = (data: SclaApplication) => {
    const { promptInfo: prompts } = data;
    const map: IUpdateReturnerSclaPrompts = {
      returnerSkillPrompt: prompts.returnerSkillPrompt || '',
      returnerCuriosityPrompt: prompts.returnerCuriosityPrompt || '',
      returnerCreedPrompt: prompts.returnerCreedPrompt || '',
    };
    setFormValue(map);
  };

  useEffect(() => {
    mapDefaultVales(app);
  }, [app]);

  const onSubmit = async () => {
    try {
      // verify that form is valid
      if (getFormValidationStatus(model.check(formValue))) {
        // show spinner
        setIsLoading(true);
        const response = await updateSclaReturnerAppPrompts(app._id, {
          ...formValue,
        });
        setApplication({ ...app, promptInfo: response.promptInfo });
        openSuccessToaster('Saved prompts.', 3000);
        setIsLoading(false);
        navToSclaAppTranscripts();
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: IUpdateReturnerSclaPrompts) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
