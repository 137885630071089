import React from 'react';
import { DropdownLink, NavigationLink } from 'hooks/useNavigationLinks';
import styled from 'styled-components';
import { UserSession } from 'types';
import { getApplication } from 'store/slices/userSessionSlice';
import { SclaAppStatus } from 'features/scla/types';
import { useAppSelector } from '../../../store/hooks';
import { SclaAppStatusTag } from '../../Elements/Tag/SclaAppStatusTag';

export interface SideNavLinkProps {
  link: NavigationLink; // link to display
  toggleLinkDropdown: (link: DropdownLink) => void; // open link dropdown
  closeSideNav: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 20px;
  align-items: center;
  gap: 14px;
`;

const Thumbnail = styled.div`
  flex: 0 0 45px;
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;

  img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
`;

const ThumbnailInfo = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 16px;
    color: #fff;
  }

  @media (min-width: 768px) {
    flex: 1;
  }
`;

const TagContainer = styled.div``;

/**
 * Side menu that contains all the page links
 * Links are shown based on the user account type
 * @param param0
 * @returns
 */
function SideNavHeader({ user }: { user: UserSession }) {
  const application = useAppSelector(getApplication);

  return (
    <Container>
      <Thumbnail>
        <img
          style={{ width: '100%' }}
          src={
            user.profileImage !== ''
              ? user.profileImage
              : `https://robohash.org/${user._id}`
          }
          alt="User profile"
        />
      </Thumbnail>

      <ThumbnailInfo>
        <h1>{user.name}</h1>
        <TagContainer>
          {application && (
            <SclaAppStatusTag
              type={application.appStatus as SclaAppStatus}
              size="sm"
            />
          )}
        </TagContainer>
      </ThumbnailInfo>
    </Container>
  );
}

export default SideNavHeader;
