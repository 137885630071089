import { useNavigate } from 'react-router-dom';
import {
  getHomePath,
  getLoginPath,
  getSclaAppBackgroundPath,
  getSclaAppFamilyPath,
  getSclaAppPromptsPath,
  getSclaAppRecPath,
  getSclaAppSchoolPath,
  getSclaAppSignaturePath,
  getSclaAppTranscriptsPath,
  getUpdateProfilePath,
} from 'utils/navigation';

export default function useAppNavigation() {
  const navigate = useNavigate();

  return {
    navToLogin: () => navigate(getLoginPath()),
    navToHome: () => navigate(getHomePath()),
    navToSclaAppBackground: () => navigate(getSclaAppBackgroundPath()),
    navToSclaAppSchool: () => navigate(getSclaAppSchoolPath()),
    navToSclaAppFamily: () => navigate(getSclaAppFamilyPath()),
    navToSclaAppPrompts: () => navigate(getSclaAppPromptsPath()),
    navToSclaAppTranscripts: () => navigate(getSclaAppTranscriptsPath()),
    navToSclaAppRec: () => navigate(getSclaAppRecPath()),
    navToSclaAppSignature: () => navigate(getSclaAppSignaturePath()),
    navToUpdateProfile: () => navigate(getUpdateProfilePath())
  };
}
