import { Form } from 'rsuite';
import styled from 'styled-components';
import { TextStyleProps } from 'types';
import React from 'react';
import { COLOR_RED } from 'constants/colors';

type FormControlLabelProps = {
  required?: boolean;
  children: string | string[];
} & TextStyleProps;

const Label = styled(Form.ControlLabel)`
  white-space: pre-line;
  color: ${props => props.color || '#000'};
  font-weight: ${props => props.fontWeight || '400'};
  text-align: ${props => props.textAlign || 'left'};
  font-size: ${props =>
    props.fontSize !== undefined ? `${props.fontSize}px` : '16px'};
  font-family: 'Roboto', 'sans-serif';

  ${props =>
    props.margin !== undefined
      ? `margin:${props.margin}px;`
      : `
    margin-bottom: ${
      props.marginBottom !== undefined
        ? `${props.marginBottom}px`
        : '.5rem'
    };
    margin-top: ${
      props.marginTop !== undefined ? `${props.marginTop}px` : '.5rem'
    };
    margin-left: ${
      props.marginLeft !== undefined ? `${props.marginLeft}px` : '.5rem'
    };
    margin-right: ${
      props.marginRight !== undefined ? `${props.marginRight}px` : '.5rem'
    };
    `}

  ${props =>
    props.padding !== undefined
      ? `padding:${props.padding}px;`
      : `
    padding-bottom: ${
      props.paddingBottom !== undefined ? `${props.paddingBottom}px` : '0'
    };
    padding-top: ${
      props.paddingTop !== undefined ? `${props.paddingTop}px` : '0'
    };
    padding-left: ${
      props.paddingLeft !== undefined ? `${props.paddingLeft}px` : '0'
    };
    padding-right: ${
      props.paddingRight !== undefined ? `${props.paddingRight}px` : '0'
    };
        `}

&:hover {
    color: ${props => (props.hoverColor ? props.hoverColor : props.color)};
  }

  span {
    color: ${COLOR_RED};
  }
`;
export default function FormControlLabel({
  required,
  children,
}: FormControlLabelProps) {
  return (
    <Label>
      {children} {required && <span>*</span>}
    </Label>
  );
}
FormControlLabel.defaultProps = {
  required: false,
};
