/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import ParticlesBackground from 'components/Particles/ParticlesBackground';
import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Interests from './Interests';

const StyledContainer = styled.div`
  padding: 0px;
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
`;
export function AuthRoutes() {
  return (
    <StyledContainer>
      <ParticlesBackground />
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/interests" element={<Interests />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </StyledContainer>
  );
}
