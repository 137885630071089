/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useUserSessionOutletContext from 'hooks/useUserSessionIOutletContext';
import SclaApplicationBackground from './SclaApplicationBackground';
import SclaApplicationFamily from './SclaApplicationFamily';
import SclaApplicationPrompts from './SclaApplicationPrompts';
import SclaApplicationSchool from './SclaApplicationSchool';
import SclaApplicationSignatures from './SclaApplicationSignatures';
import SclaApplicationTranscripts from './SclaApplicationTranscripts';
import SclaAppLayout from './SclaAppLayout';
import SclaApplicationLetterOfRec from './SclaApplicationLetterOfRec';

export function SclaApplicationRoutes() {
  const { user } = useUserSessionOutletContext();

  return (
    <Routes>
      <Route path="" element={<SclaAppLayout user={user} />}>
        <Route path="background" element={<SclaApplicationBackground />} />
        <Route path="family" element={<SclaApplicationFamily />} />
        <Route path="school" element={<SclaApplicationSchool />} />
        <Route path="prompts" element={<SclaApplicationPrompts  user={user} />} />
        <Route
          path="transcripts"
          element={<SclaApplicationTranscripts />}
        />
        <Route path="rec" element={<SclaApplicationLetterOfRec />} />
        <Route path="signature" element={<SclaApplicationSignatures />} />
        <Route path="*" element={<Navigate to="background" />} />
      </Route>
    </Routes>
  );
}
