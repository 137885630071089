const formValidation = {
    errorMessages: {
        email: "Please enter a valid email.",
        firstName: "Please enter your first name.",
        lastName: "Please enter your last name.",
        phoneNumber: "Please enter your phone number.",
        school: "Please enter your school name.",
        ethnicity: "Please select your ethnicity.",
        grade: "Please select your grade level.",
        password: "Please enter your password.",
        verifyPassword: "Passwords do not match.",
        dob: "Please enter your date of birth.",
        gradeLevel: "Please enter your grade level."
    }
}

export default formValidation