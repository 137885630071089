import { SclaApplication } from 'features/scla/types';
import { makeGetRequest } from 'lib/axios';

const getSclaApp = async (
  cohortYear: string,
): Promise<SclaApplication> => {
  const url = `/application/?cohortYear=${cohortYear}`;
  const response = await makeGetRequest(url);
  return response.data.payload;
};

export default getSclaApp;
