import styled from 'styled-components';
import React from 'react';
import useUrlQuery from 'hooks/useUrlQuery';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import Logo from 'components/Images/Logo';
import ResetPasswordForm from '../components/ResetPasswordForm';

const PageContainer = styled.div`
  height: 100%;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 50px 25px;
  position: fixed;
`;
const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
  border-radius: 10px;
  padding: 24px;

  @media (min-width: 768px) {
    width: 400px;
  }
`;

const FormHeader = styled.div`
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

const LogoContainer = styled.div`
  img {
    width: 225px;
  }
`;

const TitleContainer = styled.div`
  p {
    text-align: center;
  }

  p:nth-child(2) {
    font-weight: 600;
  }
`;

function ResetPassword() {
  // get url query
  const query = useUrlQuery();
  // get reset code from url
  const token = query.get('oobCode');
  const userId = query.get('userId');

  if (token && userId) {
    return (
      <PageContainer>
        <Container>
          <FormHeader>
            <LogoContainer>
              <Logo />
            </LogoContainer>
            <TitleContainer>
              <ParagraphText marginTop={10}>Reset Password</ParagraphText>
            </TitleContainer>
          </FormHeader>

          <ResetPasswordForm token={token} />
        </Container>
      </PageContainer>
    );
  }

  return <div>ssd</div>;
}

export default ResetPassword;
