/* eslint-disable react/jsx-props-no-spreading */
import { Dropdown } from 'rsuite';
import React from 'react';
import styled from 'styled-components';

export type DropdownItem = { label: string; onClick: () => void };

type DropdownMenuProps = {
  toggleElement: JSX.Element;
  items: DropdownItem[];
  disabled?: boolean;
};

const StyledDropdownItem = styled(Dropdown.Item)`
  min-width: 150px;
  text-align: center;
`;
export function DropdownMenuItem({ label, onClick }: DropdownItem) {
  return (
    <StyledDropdownItem onClick={onClick}>{label}</StyledDropdownItem>
  );
}

export default function DropdownMenu({
  toggleElement,
  items,
  disabled = false,
  ...rest
}: DropdownMenuProps) {
  return (
    <Dropdown
      disabled={disabled}
      placement="bottomEnd"
      renderToggle={(prop: any, ref: any) => (
        <div {...prop} ref={ref}>
          {toggleElement}
        </div>
      )}
      {...rest}
    >
      {items.map(item => (
        <DropdownMenuItem
          key={item.label}
          label={item.label}
          onClick={item.onClick}
        />
      ))}
    </Dropdown>
  );
}

DropdownMenu.defaultProps = {
  disabled: undefined,
};
