import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import { getFormValidationStatus, scrollToFormErr } from 'utils/form.util';
import { FormField } from 'components/Form/CustomForm';
import { IUpdateSclaFamily, SclaApplication } from 'features/scla/types';
import useAppNavigation from 'hooks/useAppNavigation';
import updateSclaAppFamily from 'features/scla/api/updateSclaAppFamily';
import {
  PICKER_EMPLOYMENT_STATUS,
  PICKER_MARITAL_STATUS,
  PICKER_SCLA_SALARY,
} from 'constants/picker';
import { openSuccessToaster } from 'services/toast.service';

// Extract schema types for form validation
const { StringType } = Schema.Types;

const errorMessages = {
  parentsMaritalStatus: 'Please select one.',
  fatherStatus: 'Please select one.',
  motherStatus: 'Please select one.',
  totalNumSisters: 'Please enter number of sisters.',
  totalNumBrothers: 'Please enter number of brothers.',
};

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  parentsMaritalStatus: StringType().isRequired(
    errorMessages.parentsMaritalStatus,
  ),
  numOfBrothers: StringType().isRequired(errorMessages.totalNumBrothers),
  numOfSisters: StringType().isRequired(errorMessages.totalNumSisters),
  fatherStatus: StringType().isRequired(errorMessages.fatherStatus),
  fatherName: StringType(),
  fatherPlaceOfBirth: StringType(),
  fatherPhoneNumber: StringType(),
  fatherEmployer: StringType(),
  fatherOccupation: StringType(),
  fatherEducation: StringType(),
  motherStatus: StringType().isRequired(errorMessages.motherStatus),
  motherName: StringType(),
  motherPlaceOfBirth: StringType(),
  motherPhoneNumber: StringType(),
  motherEmployer: StringType(),
  motherOccupation: StringType(),
  motherEducation: StringType(),
  motherSalary: StringType(),
  motherEmail: StringType().isEmail(),
  fatherSalary: StringType(),
  fatherEmail: StringType().isEmail(),
});

const INIT_FORM: IUpdateSclaFamily = {
  parentsMaritalStatus: '',
  numOfBrothers: '',
  numOfSisters: '',
  fatherStatus: '',
  fatherName: '',
  fatherPlaceOfBirth: '',
  fatherPhoneNumber: '',
  fatherEmployer: '',
  fatherOccupation: '',
  fatherEducation: '',
  motherStatus: '',
  motherName: '',
  motherPlaceOfBirth: '',
  motherPhoneNumber: '',
  motherEmployer: '',
  motherOccupation: '',
  motherEducation: '',
  motherSalary: '',
  motherEmail: '',
  fatherSalary: '',
  fatherEmail: '',
};

const fields: FormField[] = [
  {
    name: 'parentsMaritalStatus',
    type: 'select',
    label: "Parent's Marital Status",
    size: 'lg',
    data: {
      picker: PICKER_MARITAL_STATUS,
      searchable: false,
    },
    required: true,
  },
  {
    name: 'fatherStatus',
    type: 'select',
    label: 'Father Status',
    size: 'sm',
    data: {
      picker: PICKER_EMPLOYMENT_STATUS,
      searchable: false,
    },
    required: true,
  },
  {
    name: 'motherStatus',
    type: 'select',
    label: 'Mother Status',
    size: 'sm',
    data: {
      picker: PICKER_EMPLOYMENT_STATUS,
      searchable: false,
    },
    required: true,
  },
  {
    name: 'numOfBrothers',
    type: 'text',
    label: 'How many brothers?',
    size: 'sm',
    data: null,
    required: true,
  },
  {
    name: 'numOfSisters',
    type: 'text',
    label: 'How many sisters?',
    size: 'sm',
    data: null,
    required: true,
  },

  {
    name: 'fatherName',
    type: 'text',
    label: 'Father Name',
    size: 'sm',
    data: null,
  },

  {
    name: 'fatherPlaceOfBirth',
    type: 'text',
    label: 'Father Place Of Birth',
    size: 'sm',
    data: null,
  },

  {
    name: 'fatherPhoneNumber',
    type: 'phoneNumber',
    label: 'Father Home Or Cell Phone',
    size: 'sm',
    data: null,
  },

  {
    name: 'fatherOccupation',
    type: 'text',
    label: 'Father Job/Occupation',
    size: 'sm',
    data: null,
  },

  {
    name: 'fatherEmployer',
    type: 'text',
    label: 'Father Employer/Place of work',
    size: 'sm',
    data: null,
  },

  {
    name: 'fatherSalary',
    type: 'select',
    label: 'Father Annual Salary',
    size: 'sm',
    data: {
      picker: PICKER_SCLA_SALARY,
      searchable: false,
    },
  },

  {
    name: 'fatherEmail',
    type: 'email',
    label: 'Father Email',
    size: 'sm',
    data: null,
  },

  {
    name: 'fatherEducation',
    type: 'text',
    label: 'Father Highest Grade Completed',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherName',
    type: 'text',
    label: 'Mother Name',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherPlaceOfBirth',
    type: 'text',
    label: 'Mother Place Of Birth',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherPhoneNumber',
    type: 'phoneNumber',
    label: 'Mother Home Or Cell Phone',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherOccupation',
    type: 'text',
    label: 'Mother Job/Occupation',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherEmployer',
    type: 'text',
    label: 'Mother Employer/Place of work',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherSalary',
    type: 'select',
    label: 'Mother Annual Salary',
    size: 'sm',
    data: {
      picker: PICKER_SCLA_SALARY,
      searchable: false,
    },
  },

  {
    name: 'motherEmail',
    type: 'email',
    label: 'Mother Email',
    size: 'sm',
    data: null,
  },

  {
    name: 'motherEducation',
    type: 'text',
    label: 'Mother Highest Grade Completed',
    size: 'sm',
    data: null,
  },
];

export default function useUpdateSclaAppFamily(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const [formValue, setFormValue] = useState<IUpdateSclaFamily>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process
  const { navToSclaAppSchool } = useAppNavigation();

  const mapDefaultVales = (data: SclaApplication) => {
    const { familyInfo: family } = data;

    const map: IUpdateSclaFamily = {
      parentsMaritalStatus: family.parentsMaritalStatus || '',
      numOfBrothers: family.numOfBrothers || '',
      numOfSisters: family.numOfSisters || '',
      fatherStatus: family.fatherStatus || '',
      fatherName: family.fatherName || '',
      fatherPlaceOfBirth: family.fatherPlaceOfBirth || '',
      fatherPhoneNumber: family.fatherPhoneNumber || '',
      fatherEmployer: family.fatherEmployer || '',
      fatherOccupation: family.fatherOccupation || '',
      fatherEducation: family.fatherEducation || '',
      motherStatus: family.motherStatus || '',
      motherName: family.motherName || '',
      motherPlaceOfBirth: family.motherPlaceOfBirth || '',
      motherPhoneNumber: family.motherPhoneNumber || '',
      motherEmployer: family.motherEmployer || '',
      motherOccupation: family.motherOccupation || '',
      motherEducation: family.motherEducation || '',
      motherEmail: family.motherEmail || '',
      motherSalary: family.motherSalary || '',
      fatherEmail: family.fatherEmail || '',
      fatherSalary: family.fatherSalary || '',
    };
    setFormValue(map);
  };

  useEffect(() => {
    mapDefaultVales(app);
  }, [app]);

  const onSubmit = async () => {
    try {
      if (getFormValidationStatus(model.check(formValue))) {
        setIsLoading(true);

        const response = await updateSclaAppFamily(app._id, {
          ...formValue,
        });
        setApplication({ ...app, familyInfo: response.familyInfo });
        openSuccessToaster('Saved Family Info', 3000);
        setIsLoading(false);
        navToSclaAppSchool();
      } else {
        scrollToFormErr();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: IUpdateSclaFamily) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
