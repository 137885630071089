import React from 'react';
import { useRoutes } from 'react-router-dom';
import { getUser } from 'store/slices/userSessionSlice';
import useAuth from 'hooks/useAuth';
import Loader from 'components/Elements/Loader/Loader';
import publicRoutes from './public';
import { useAppSelector } from '../store/hooks';
import protectedRoutes from './protected';

export default function AppRoutes() {
  // check for authorized user
  const { isDone } = useAuth();

  // fetch user profile
  const user = useAppSelector(getUser);

  // render protected routes if we have token
  const routes = user ? protectedRoutes : publicRoutes;

  // create routes
  const element = useRoutes([...routes]);

  if (isDone) {
    return element;
  }

  // render
  return <Loader theme="dark" isVisible fullscreen />;
}
