import { IUpdateReturnerSclaPrompts, SclaApplication } from 'features/scla/types';
import { makePutRequest } from 'lib/axios';

const updateSclaReturnerAppPrompts = async (
  id: string,
  payload: IUpdateReturnerSclaPrompts,
): Promise<SclaApplication> => {
  const url = `/application/returner-prompts/${id}`;
  const response = await makePutRequest(url, payload);
  return response.data.payload;
};

export default updateSclaReturnerAppPrompts;
