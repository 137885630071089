import { useEffect } from "react";

export default function useWindowResizeObserver() {
  useEffect(() => {
    window.addEventListener('error', e => {

      if (e.message.toLowerCase().includes('resizeobserver')) {
        // eslint-disable-next-line no-console
        console.warn('ResizeObserver error was silenced.', e)
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);
}
