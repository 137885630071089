import { SclaApplication } from 'features/scla/types';
import { makePutRequest } from 'lib/axios';

const submitSclaApp = async (id: string): Promise<SclaApplication> => {
  const url = `/application/submit/${id}`;
  const response = await makePutRequest(url, {});
  return response.data.payload;
};

export default submitSclaApp;
