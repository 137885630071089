import React, { useEffect, useState } from 'react';
import {
  faIdBadge,
  faPeopleGroup,
  faSchool,
  faFile,
  faCheckSquare,
  faListDots,
} from '@fortawesome/free-solid-svg-icons';
import { UserSession } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser } from 'store/slices/userSessionSlice';
import {
  getSclaAppBackgroundPath,
  getSclaAppFamilyPath,
  getSclaAppPromptsPath,
  getSclaAppRecPath,
  getSclaAppSchoolPath,
  getSclaAppSignaturePath,
  getSclaAppTranscriptsPath,
} from 'utils/navigation';
import { useAppSelector } from '../store/hooks';

export type Link = {
  title: string;
  icon: JSX.Element;
  to: string;
  type: 'internal' | 'external';
};

/**
 * Interface side menu dropdown links
 */
export type DropdownLink = {
  title: string;
  icon: JSX.Element;
  active: boolean;
  children: Link[];
};

export type NavigationLink = {
  type: 'dropdown' | 'single';
  data: Link | DropdownLink;
};

/**
 * Side Menu links
 */
const LINKS: NavigationLink[] = [
  {
    type: 'single',
    data: {
      title: 'Background',
      icon: <FontAwesomeIcon icon={faIdBadge} />,
      to: getSclaAppBackgroundPath(),
      type: 'internal',
    },
  },

  {
    type: 'single',
    data: {
      title: 'Family',
      icon: <FontAwesomeIcon icon={faPeopleGroup} />,
      to: getSclaAppFamilyPath(),
      type: 'internal',
    },
  },

  {
    type: 'single',
    data: {
      title: 'School',
      icon: <FontAwesomeIcon icon={faSchool} />,
      to: getSclaAppSchoolPath(),
      type: 'internal',
    },
  },

  {
    type: 'single',
    data: {
      title: 'Prompts',
      icon: <FontAwesomeIcon icon={faListDots} />,
      to: getSclaAppPromptsPath(),
      type: 'internal',
    },
  },

  {
    type: 'single',
    data: {
      title: 'Transcripts',
      icon: <FontAwesomeIcon icon={faFile} />,
      to: getSclaAppTranscriptsPath(),
      type: 'internal',
    },
  },

  {
    type: 'single',
    data: {
      title: 'Signatures',
      icon: <FontAwesomeIcon icon={faCheckSquare} />,
      to: getSclaAppSignaturePath(),
      type: 'internal',
    },
  },

  {
    type: 'single',
    data: {
      title: 'Letter Of Recommendation',
      icon: <FontAwesomeIcon icon={faFile} />,
      to: getSclaAppRecPath(),
      type: 'internal',
    },
  },
];

export default function useNavigationLinks() {
  const [links, setLinks] = useState<NavigationLink[]>([]);

  const user = useAppSelector(getUser);

  useEffect(() => {
    let mounted = true;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function filterLinks(userData: UserSession) {
      setLinks(LINKS);
    }

    if (mounted && user) filterLinks(user);

    return () => {
      mounted = false;
    };
  }, [user]);

  const toggleLinkDropdown = (link: DropdownLink) => {
    // eslint-disable-next-line no-param-reassign
    link.active = !link.active;
    setLinks([...links]);
  };

  return { links, toggleLinkDropdown };
}
