import { useEffect, useState } from 'react';
import { Schema } from 'rsuite';
import serverErrorHandler from 'services/serverError.service';
import { getFormValidationStatus } from 'utils/form.util';
import { FormField } from 'components/Form/CustomForm';
import {
  IUpdateSclaSchool,
  SclaApplication,
} from 'features/scla/types';
import updateSealsAppSchool from 'features/scla/api/updateSclaAppSchool';
import useAppNavigation from 'hooks/useAppNavigation';
import formValidation from 'constants/validation';
import { PICKER_SCLA_CAREER } from 'constants/picker';
import { openSuccessToaster } from 'services/toast.service';

// Extract schema types for form validation
const { StringType } = Schema.Types;

/**
 * Define validation model for login form.
 * User must provide a valid email and password
 * in order to submit the form.
 *
 * rsuite(5.5.2): https://rsuitejs.com/components/form-validation/
 */
const model = Schema.Model({
  schoolName: StringType().isRequired('Please enter your school name.'),
  counselorName: StringType().isRequired('Please enter your school counselor\'s name.'),
  counselorPhoneNumber: StringType(),
  counselorEmail: StringType().isEmail(
    formValidation.errorMessages.email,
  ),
  gpa: StringType(),
  hobbies: StringType(),
});

const INIT_FORM: IUpdateSclaSchool = {
  schoolName: '',
  counselorName: '',
  counselorPhoneNumber: '',
  counselorEmail: '',
  gpa: '',
  extraCurriculars: [],
  careerInterests: [],
  hobbies: '',
};

const fields: FormField[] = [
  {
    name: 'schoolName',
    type: 'text',
    label: 'School Name',
    size: 'sm',
    data: null,
    required: true
  },
  {
    name: 'counselorName',
    type: 'text',
    label: 'School Counselor',
    size: 'sm',
    data: null,
    required: true
  },
  {
    name: 'counselorPhoneNumber',
    type: 'text',
    label: 'Counselor/Teacher Phone Number',
    size: 'sm',
    data: null,
  },
  {
    name: 'counselorEmail',
    type: 'text',
    label: 'Counselor/Teacher Email',
    size: 'sm',
    data: null,
  },
  {
    name: 'gpa',
    type: 'text',
    label: 'Grade Point Average (GPA)',
    size: 'lg',
    data: null,
  },

  {
    name: 'hobbies',
    type: 'textarea',
    label: 'Hobbies & Interests?',
    size: 'lg',
    data: {
      rows: 10,
    },
  },

  {
    name: 'careerInterests',
    type: 'multi_select',
    label: 'What career fields are you interested in pursuing?',
    size: 'lg',
    data: {
      picker: PICKER_SCLA_CAREER,
      searchable: false,
    },
  },
  {
    name: 'extraCurriculars',
    type: 'scla_activities',
    label: 'Activities / Work Experience',
    size: 'lg', 
    data: null
  },
  
];

export default function useUpdateSclaAppSchool(
  app: SclaApplication,
  setApplication: (val: SclaApplication) => void,
) {
  const [formValue, setFormValue] = useState<IUpdateSclaSchool>(INIT_FORM); // set default form values
  const [isLoading, setIsLoading] = useState(false); // flag for submission process
  const { navToSclaAppPrompts } = useAppNavigation();


  const mapDefaultVales = (data: SclaApplication) => {
    const { schoolInfo: school } = data;
    const map: IUpdateSclaSchool = {
      schoolName: school.schoolName || '',
      counselorName: school.counselorName || '',
      counselorPhoneNumber: school.counselorPhoneNumber || '',
      counselorEmail: school.counselorEmail || '',
      gpa: school.gpa || '',
      careerInterests: school.careerInterests,
      extraCurriculars: school.extraCurriculars || '',
      hobbies: school.hobbies || '',
    };
    setFormValue(map);
  };

  useEffect(() => {
    mapDefaultVales(app);
  }, [app]);

  const onSubmit = async () => {
    try {
      // verify that form is valid
      if (getFormValidationStatus(model.check(formValue))) {
        // show spinner
        setIsLoading(true);
        const response = await updateSealsAppSchool(app._id, {
          ...formValue,
        });
        openSuccessToaster("Saved School info", 3000);
        setApplication({ ...app, schoolInfo: response.schoolInfo });
        setIsLoading(false);
        navToSclaAppPrompts();
      }
    } catch (e) {
      // hide spinner
      setIsLoading(false);
      serverErrorHandler(e);
    }
  };

  const onChange = (val: IUpdateSclaSchool) => {
    setFormValue(val);
  };

  return {
    fields,
    formValue,
    isLoading,
    model,
    onChange,
    onSubmit,
  };
}
