import React from 'react';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { COLOR_PRIMARY } from 'constants/colors';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';


interface IDefaultContainerProps {
  links: any[];
  children: any; // elements to be rendered inside the body of container
}

const StyledContainer = styled.div`
  width: 100%;
  margin: auto;
  margin-bottom: 35px;
  padding-bottom: 100px;
  @media (min-width: 1024px) {
    max-width: 850px;
  }
`;

const Wrapper = styled.div`
  /* background-color: #fff; */
  /* border-radius: 6px; */
  border: none;
  padding-left: 0px;
  padding-right: 0px;
`;

const ContainerContent = styled.div`
  padding: 1.25rem 1rem;
`;

const Tab = styled(NavLink)`
  flex: 1 0 120px;
  display: flex;
  line-height: 1.5rem;
  padding: 0.675rem 1.25rem;
  color: #fff;
  text-decoration: none !important;
  align-items: center;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom: solid 3px transparent;
  &:hover {
    background-color: #00466c;
  }
  p {
    color: #fff;
    text-align: center;
    font-size: 15px;
  }
  svg {
    color: #fff;
  }

  &.active {
    border-bottom: solid 3px rgb(240, 90, 34);
    background-color: #00466c;
  }
`;

const TabNavigation = styled.div`
  display: flex;
  background-color: ${COLOR_PRIMARY};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;

  ${Tab} {
    border-right: 1px solid #fff;
  }

  ${Tab}:last-of-type {
    border: none;
  }
`;

/**
 * Renders a container with head and body section. The head
 * contains the container title and menu options (if provided).
 * The body contains the children passed to the component
 * @param props
 * @returns
 */
export default function ApplicationContentContainer(
  props: IDefaultContainerProps,
) {
  const { links, children } = props;



  return (
    <StyledContainer>
      <Wrapper>
        <TabNavigation>
          {links.map(link => (
            <Tab key={link.label} to={link.to} end>
              {link.complete ? (
                <FontAwesomeIcon icon={faCircleCheck} />
              ) : (
                <FontAwesomeIcon icon={faCircle as IconProp} />
              )}
              <p>{link.label}</p>
            </Tab>
          ))}
        </TabNavigation>
        <ContainerContent>{children}</ContainerContent>
      </Wrapper>
    </StyledContainer>
  );
}
