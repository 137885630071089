import React, { Suspense } from 'react';
import Loader from 'components/Elements/Loader/Loader';
import { AuthRoutes } from 'features/auth';

const publicRoutes = [
  {
    path: '/*',
    element: (
      <Suspense fallback={<Loader theme="dark" isVisible fullscreen />}>
        <AuthRoutes />
      </Suspense>
    ),
  },
];

export default publicRoutes;
