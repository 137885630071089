import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import {
  getSclaAppBackgroundPath,
  getSclaAppFamilyPath,
  getSclaAppPromptsPath,
  getSclaAppSchoolPath,
  getSclaAppTranscriptsPath,
} from 'utils/navigation';

import { SclaApplication } from '../types';
import ProgramAlert from './ProgramAlert';

interface IApplicationStatusProps {
  application: SclaApplication;
}

const Alerts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin-bottom: 20px;
`;

export default function SignatureApplicationStatus(
  props: IApplicationStatusProps,
) {
  const { application } = props;
  const [status, setStatus] = useState('incomplete');
  const [incompleteSections, setIncompleteSections] = useState<any[]>([]);

  const {
    backgroundInfo,
    schoolInfo,
    familyInfo,
    promptInfo,
    transcriptsInfo,
    signatureInfo,
    appStatus,
  } = application;

  useEffect(() => {
    if (
      backgroundInfo.sectionComplete &&
      schoolInfo.sectionComplete &&
      familyInfo.sectionComplete &&
      promptInfo.sectionComplete &&
      signatureInfo.sectionComplete &&
      appStatus === 'submitted'
    ) {
      setStatus('submitted');
    } else if (
      backgroundInfo.sectionComplete &&
      schoolInfo.sectionComplete &&
      familyInfo.sectionComplete &&
      promptInfo.sectionComplete
    ) {
      setStatus('complete');
    } else {
      const sections: any = [];

      if (!backgroundInfo.sectionComplete) {
        sections.push({
          label: 'Background Section',
          to: getSclaAppBackgroundPath(),
        });
      }

      if (!schoolInfo.sectionComplete) {
        sections.push({
          label: 'Family Section',
          to: getSclaAppFamilyPath(),
        });
      }

      if (!familyInfo.sectionComplete) {
        sections.push({
          label: 'School Section',
          to: getSclaAppSchoolPath(),
        });
      }

      if (!promptInfo.sectionComplete) {
        sections.push({
          label: 'Prompts Section',
          to: getSclaAppPromptsPath(),
        });
      }

      setIncompleteSections(sections);

      setStatus('incomplete');
    }
  }, [application]);

  if (status === 'incomplete') {

    console.log(status)
    return (
      <Alerts>
        <ProgramAlert type="danger">
          <p>Your application is still incomplete.</p>
          <p>
            Finish all the application sections before attempting to submit
            your application.
          </p>
          <p>Please complete</p>
          <ol>
            {incompleteSections.map(section => (
              <li key={section.label}>
                <NavLink to={section.to}>{section.label}</NavLink>
              </li>
            ))
            }
          </ol>
        </ProgramAlert>
      </Alerts>
    );
  }

  return <div />;
}
