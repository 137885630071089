import React, { forwardRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import Button from 'components/Elements/Button/Button';
import Loader from 'components/Elements/Loader/Loader';
import { IFile } from 'types';
import { SclaApplicationOutletContext } from './SclaAppLayout';
import SignatureApplicationStatus from '../components/SignatureApplicationStatus';
import useUpdateSclaAppSignature from '../hooks/useUpdateSclaAppSignature';

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

const CanvasContainer = styled.div`
  border: solid thin black;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
  align-self: center;

  img {
    width: 100%;
    height: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 16px;
`;

const SignatureContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 8px;
  gap: 10px;
  height: auto;

  @media (min-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    height: 325px;
  }
`;

const SignatureImgContainer = styled.div`
  width: 100%;
  padding: 20px 20px;
  img {
    width: 100%;
    object-fit: cover;
  }
`;

const Signature = forwardRef(
  (
    {
      label,
      signature,
      isLoading,
      onSubmit,
      onClear,
    }: {
      label: string;
      signature: IFile;
      isLoading: boolean;
      onSubmit: () => Promise<void>;
      onClear: () => void;
    },
    ref: React.ForwardedRef<SignatureCanvas>,
  ) => (
    <SignatureContainer>
      <h1>{label}</h1>

      {signature ? (
        <SignatureImgContainer>
          <img src={signature.url} alt="signature" />
        </SignatureImgContainer>
      ) : (
        <>
          <p>Please use your mouse or finger to sign below.</p>
          <CanvasContainer>
            <SignatureCanvas
              ref={ref}
              clearOnResize={false}
              penColor="black"
              canvasProps={{
                width: 700,
                height: 200,
                className: 'sigCanvas',
              }}
            />
          </CanvasContainer>
          <ButtonContainer>
            <Button
              color="grey"
              size="sm"
              type="button"
              isLoading={false}
              disabled={isLoading}
              onClick={onClear}
            >
              <p> Clear </p>
            </Button>

            <Button
              size="md"
              type="button"
              isLoading={isLoading}
              disabled={isLoading}
              onClick={onSubmit}
            >
              <p> Save Student Signature </p>
            </Button>
          </ButtonContainer>
        </>
      )}
    </SignatureContainer>
  ),
);

export default function SclaApplicationSignatures() {
  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  const {
    isDone,
    sectionsComplete,
    parentSignature,
    studentSignature,
    isUploadStudent,
    isUploadingParent,
    studentSignatureRef,
    parentSignatureRef,
    isSubmitting,
    onClearParentSignature,
    onClearStudentSignature,
    onUploadParentSignature,
    onUploadStudentSignature,
    onSubmitApplication,
  } = useUpdateSclaAppSignature(application, setApplication);

  if (!isDone) {
    return <Loader fullscreen={false} isVisible theme="light" />;
  }

  // if (!sectionsComplete) {
  //   return (
  //     <Container>
  //       <SignatureApplicationStatus application={application} />
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <SignatureApplicationStatus application={application} />

      <Signature
        label="Parent Signature"
        ref={parentSignatureRef}
        signature={parentSignature}
        isLoading={isUploadingParent}
        onSubmit={onUploadParentSignature}
        onClear={onClearParentSignature}
      />

      <Signature
        label="Student Signature"
        ref={studentSignatureRef}
        signature={studentSignature}
        isLoading={isUploadStudent}
        onSubmit={onUploadStudentSignature}
        onClear={onClearStudentSignature}
      />

      <ButtonContainer>
        <Button
          size="sm"
          type="button"
          isLoading={isSubmitting}
          onClick={onSubmitApplication}
        >
          <p> Submit Application </p>
        </Button>
      </ButtonContainer>
    </Container>
  );
}

// <SignatureContainer>
// <h1>Student Signature</h1>

// {studentSignature ? (
//   <SignatureContainer>
//     <img src={studentSignature.url} alt="signature" />
//   </SignatureContainer>
// ) : (
//   <>
//     <p>Please use your mouse or finger to sign below.</p>
//     <CanvasContainer>
//       <SignatureCanvas
//         ref={studentSignatureRef}
//         clearOnResize={false}
//         penColor="black"
//         canvasProps={{
//           width: 700,
//           height: 200,
//           className: 'sigCanvas',
//         }}
//       />
//     </CanvasContainer>
//     <ButtonContainer>
//       <Button
//         color="grey"
//         size="sm"
//         type="button"
//         isLoading={false}
//         disabled={isUploadStudent}
//         onClick={onClearStudentSignature}
//       >
//         <p> Clear </p>
//       </Button>

//       <Button
//         size="md"
//         type="button"
//         isLoading={isUploadStudent}
//         disabled={isUploadStudent}
//         onClick={onUploadStudentSignature}
//       >
//         <p> Save Student Signature </p>
//       </Button>
//     </ButtonContainer>
//   </>
// )}
// </SignatureContainer>
