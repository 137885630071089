import React from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import { url } from 'constants/api.constants';
import useAppNavigation from 'hooks/useAppNavigation';
import Button from 'components/Elements/Button/Button';
import { COLOR_GREEN } from 'constants/colors';
import {
  getSclaAppPromptsPath,
  getSclaAppSignaturePath,
} from 'utils/navigation';
import FileUploader from 'components/Elements/FileUploader/FileUploader';
import { SclaApplicationOutletContext } from './SclaAppLayout';
import useUpdateSclaAppTranscripts from '../hooks/useUpdateSclaAppTranscripts';


const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  h1 {
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
    line-height: 28px;
  }
`;

const NavButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const NavButtonLink = styled(NavLink)`
  display: flex;
  justify-content: center;
  background-color: ${COLOR_GREEN};
  padding: 10px 15px;
  border-radius: 4px;
  p {
    color: #fff;
    font-size: 15px;
    line-height: 15px;
  }
`;

const Description = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  align-items: flex-start;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  margin-bottom: 20px;
  h5 {
    width: 100%;
    text-align: left;
  }
`;
export default function SclaApplicationTranscripts() {
  const { application, setApplication }: SclaApplicationOutletContext =
    useOutletContext();

  const { files, isLoading, onRemove, onError, onSuccess, setIsLoading } =
    useUpdateSclaAppTranscripts(application, setApplication);

  const { navToSclaAppSignature } = useAppNavigation();

  return (
    <>
      <Header>
        <h1>Transcripts</h1>
        <NavButtons>
          <NavButtonLink to={getSclaAppPromptsPath()}>
            <p>Previous</p>
          </NavButtonLink>
          <NavButtonLink to={getSclaAppSignaturePath()}>
            <p>Next</p>
          </NavButtonLink>
        </NavButtons>
      </Header>

      <Description>
        <h5>What is a Transcript?</h5>
        <p>
          Your high school transcript is a complete record of your academic
          achievements in high school. It starts with the first grading
          period of your first year of high school and is updated each term
          until you graduate.
        </p>
        <p>
          We ask for your <b>Unofficial Transcripts</b> you can get yours
          by asking your school counselor.
        </p>

        <p>
          <a
            href="https://blog.collegeboard.org/whats-a-high-school-transcript"
            target="_blank"
            rel="noreferrer"
          >
            Learn more about Transcripts here!
          </a>
        </p>
      </Description>
      <div>
        <FileUploader
          setIsLoading={setIsLoading}
          files={files}
          isLoading={isLoading}
          onRemove={onRemove}
          onError={onError}
          onSuccess={onSuccess}
          url={`${url.API_URL}/application/transcripts/${application._id}`}
        />

        <ButtonContainer>
          <Button
            size="sm"
            type="button"
            isLoading={false}
            onClick={navToSclaAppSignature}
          >
            <p> Save and Continue </p>
          </Button>
        </ButtonContainer>
      </div>
    </>
  );
}
