import { SclaApplication } from 'features/scla/types';
import { makePostRequest } from 'lib/axios';

const startSclaApp = async (
  cohortYear: string,
): Promise<SclaApplication> => {
  const url = `/application`;
  const response = await makePostRequest(url, { cohortYear });
  return response.data.payload;
};

export default startSclaApp;
