
import { SclaApplication } from 'features/scla/types';
import {  makePutRequest } from 'lib/axios';

const removeSclaAppTranscripts = async (
  id: string,
  payload: { fileId: string },
): Promise<SclaApplication> => {
  const url = `/application/transcripts/${id}`;
  const response = await makePutRequest(url, payload);
  return response.data.payload;
};

export default removeSclaAppTranscripts;
