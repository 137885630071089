import { MyTxtUserSignUpDto } from 'dtos/user.dto';
import { makePostRequest } from 'lib/axios';

const signUp = async (
  payload: MyTxtUserSignUpDto,
): Promise<{ message: string }> => {
  const url = `/auth/sign-up`;
  const response = await makePostRequest(url, payload);
  return response.data.message;
};

export default signUp;
