/* eslint-disable prefer-promise-reject-errors */
import axios, { AxiosRequestConfig } from 'axios';
import { url } from 'constants/api.constants';
import refreshTokenId from 'utils/firebase.util';

// sets the server api url, as the base url for all requests
// made using this axios instance
const axiosInstance = axios.create({
  baseURL: url.API_URL,
});

// requests will be json by default
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

export default axiosInstance;

/**
 * Makes request for refresh firebase auth token
 * Sets updated refreshed token as axios header
 * for server validation
 */
const setUpdatedToken = async () => {
  try {
    // req updated user id token
    const token = await refreshTokenId();
    // set token in header
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    return Promise.resolve('done');
  } catch (e) {
    return Promise.reject({
      status: 400,
      errorMessage:
        'An error occurred with Firebase refresh token. Please refresh the page and try again.',
    });
  }
};

/**
 * Makes get request
 * @param url
 * @returns
 * - api request payload
 */
export const makeGetRequest = async (reqUrl: string): Promise<any> => {
  // get most up to date firebase token
  await setUpdatedToken();
  return axiosInstance.get<any>(reqUrl);
};

/**
 * Makes post request
 * @param url
 * @param payload
 * @returns
 */
export const makePostRequest = async (
  reqUrl: string,
  payload: any,
  config: AxiosRequestConfig = {},
) => {
  // get most up to date firebase token
  await setUpdatedToken();
  return axiosInstance.post<any>(reqUrl, payload, config);
};

/**
 * Makes put request
 * @param url
 * @param payload
 * @returns
 */
export const makePutRequest = async (
  reqUrl: string,
  payload: any,
  config: AxiosRequestConfig = {},
) => {
  // get most up to date firebase token
  await setUpdatedToken();
  return axiosInstance.put<any>(reqUrl, payload, config);
};

/**
 * Makes delete request
 * @param url
 * @param payload
 * @returns
 */
export const makeDeleteRequest = async (reqUrl: string) => {
  // get most up to date firebase token
  await setUpdatedToken();
  return axiosInstance.delete<any>(reqUrl);
};
