// styles
// import classes from './LoginForm.module.css';
import styled from 'styled-components';
import React from 'react';
import { Form } from 'rsuite';
import CheckboxFormField from 'components/Form/Input/Base/CheckboxFormField';
import TextFormField from 'components/Form/Input/Base/TextFormField';
import useSignIn from 'features/auth/hooks/useSignIn';
import ParagraphText from 'components/Elements/ParagraphText/ParagraphText';
import Button from 'components/Elements/Button/Button';
import { NavLink } from 'react-router-dom';

/**
 * Checkbox fields for Remember me section.
 */
const checkboxes = [
  {
    label: 'Remember me',
    value: 'true',
  },
];

const Container = styled.div`
  max-width: 100%;
  background-color: #fff;

  @media (min-width: 768px) {
    max-width: 400px;
  }

  @media (min-width: 992px) {
    max-width: 400px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const CustomLink = styled(NavLink)`
  p {
    font-size: 15px;
    color: var(--color-blue);

    @media (min-width: 992px) {
      &:hover {
        color: var(--color-black);
      }
    }
  }
`;

/**
 * Renders login form that allows users to login using their credentials.
 * Users must provide a valid email and password to login.
 * @returns
 */
function LoginForm() {
  // get sign in form state
  const { model, formValue, isLoading, submit, setFormValue } =
    useSignIn();

  return (
    <Container>
      <Form
        fluid
        model={model}
        formValue={formValue}
        onChange={setFormValue}
      >
        <TextFormField size="lg" type="email" name="email" label="Email" />

        <TextFormField
          size="lg"
          type="password"
          name="password"
          label="Password"
        />

        <CheckboxFormField
          name="saveEmail"
          label=""
          checkboxes={checkboxes}
        />

        <Button
          size="full"
          type="submit"
          isLoading={isLoading}
          onClick={submit}
        >
          <ParagraphText textAlign="center" margin={0} color="#fff">
            Sign In
          </ParagraphText>
        </Button>

        <LinksContainer>
          <CustomLink to="/forgot-password">
            <p>Forgot password?</p>
          </CustomLink>
          <CustomLink to="/sign-up">
            <p>Sign up.</p>
          </CustomLink>
        </LinksContainer>
      </Form>
    </Container>
  );
}

export default LoginForm;
