import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useWindowResizeObserver from 'hooks/useWindowResizeObserver';
import '../styles/main.scss';

type AppProviderProps = {
  children: React.ReactNode;
};

const description = ``;

export default function AppProvider({ children }: AppProviderProps) {
  useWindowResizeObserver();
  return (
    <>
      <Helmet>
        <title>URBAN TXT - SCLA</title>
        <meta name="description" content={description} />
      </Helmet>

      <Router>{children}</Router>
    </>
  );
}
