const storagePrefix = 'txt_seals_';

const storage = {
  getLoginEmail: () =>
    JSON.parse(
      window.localStorage.getItem(`${storagePrefix}email`) as string,
    ),
  setLoginEmail: (email: string) => {
    window.localStorage.setItem(
      `${storagePrefix}email`,
      JSON.stringify(email),
    );
  },
  clearLoginEmail: () => {
    window.localStorage.removeItem(`${storagePrefix}email`);
  },
};

export default storage;
