import styled from 'styled-components';
import React from 'react';
import { NavLink } from 'react-router-dom';
import CustomForm from 'components/Form/CustomForm';
import useSignup from '../hooks/useSignUp';

const Container = styled.div`
  max-width: 100%;
  background-color: #fff;
  border-radius: 10px;

  @media (min-width: 768px) {
    max-width: 400px;
  }

  @media (min-width: 992px) {
    max-width: 400px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const CustomLink = styled(NavLink)`
  p {
    font-size: 15px;
    color: var(--color-blue);

    @media (min-width: 992px) {
      &:hover {
        color: var(--color-black);
      }
    }
  }
`;

function SignUpForm() {
  // get sign in form state
  const { fields, model, formValue, isLoading, onSubmit, onChange } =
    useSignup();
  return (
    <Container>
      <CustomForm
        onChange={onChange}
        onSubmit={onSubmit}
        model={model}
        formValue={formValue}
        isLoading={isLoading}
        fields={fields}
        isDisabled={false}
        submitLabel="Create Account"
        hasChanges={false}
        submitBtnSize="full"
      />
      <LinksContainer>
        <CustomLink to="/">
          <p>Already have an account? Log in here.</p>
        </CustomLink>
      </LinksContainer>
    </Container>
  );
}

export default SignUpForm;
