import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import { injectStore } from 'store/dispatcher';

type AppProviderProps = {
  children: React.ReactNode;
};

injectStore(store);

export default function StoreProvider({ children }: AppProviderProps) {
  return <Provider store={store}>{children}</Provider>;
}
