import { useEffect, useState } from 'react';
import serverErrorHandler from 'services/serverError.service';
import { useAppSelector } from 'store/hooks';
import { getApplication } from 'store/slices/userSessionSlice';
import { dispatchSetUserApplication } from 'store/dispatcher';
import { SclaApplication } from '../types';
import getSclaApp from '../api/getSclaApp';
import startSclaApp from '../api/startSclaApp';

export default function useSclaApplication(
  userId: string,
  cohortYear: string,
) {
  const application = useAppSelector(getApplication);

  const setApplication = (app: SclaApplication) => {
    dispatchSetUserApplication(app);
  };
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [isStarting, setIsStarting] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        setIsDoneLoading(false);
        const app = await getSclaApp(cohortYear);

        if (app) {
          setApplication(app);
        } else {
          const newApp = await startSclaApp(cohortYear);
          setApplication(newApp);
        }
        setIsDoneLoading(true);
      } catch (e) {
        setIsDoneLoading(true);
        serverErrorHandler(e);
      }
    }

    load();
  }, [cohortYear]);

  const startApplication = async () => {
    try {
      setIsStarting(true);
      const app = await startSclaApp(cohortYear);
      setApplication(app);
      setIsStarting(false);
    } catch (e) {
      setIsStarting(false);
      serverErrorHandler(e);
    }
  };

  return {
    application,
    isDoneLoading,
    isStarting,
    startApplication,
    setApplication,
  };
}
